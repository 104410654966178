import { AxiosRequestConfig } from "axios";
import { SupplierCreateUpdateModel, SupplierProductCreateUpdateModel } from "src/interfaces/bold/product/supplier";
import { createOrUpdateSupplier, createOrUpdateSupplierProduct } from "../services/bold/requests";

const mapProductFormValuesToSupplierProductUpdate = (values) => {
    let supplierProduct : SupplierProductCreateUpdateModel = {
         Price: values.Price,
    }
    return supplierProduct;
}
const mapProductFormValuesToSupplierProductCreate = (values) => {
    let supplierProduct : SupplierProductCreateUpdateModel = {
        SupplierAssortmentItemId: values.SupplierAssortment,
        ManufacturerId: values.Manufacturer,
        ManufacturerProduct: values.ManufacturerProduct,
         SupplierProduct: values.SupplierProduct_Value,
         Price: values.Price,
         TARIC: parseInt(values.TARIC ?? null),
         ProductsId: values.Product,
         Status: values.Status,
         Quantity: values.QuantityAvailableAtSupplier,
         ETA: values.ETA ?? null,
         ValidFrom: values.ValidFrom ?? new Date(),
         ValidTo: values.ValidTo,
         EAN: values.EAN,
    }
    return supplierProduct;
}


const mapFormValuesToSupplier = (values) => {
    let supplier:SupplierCreateUpdateModel = {
        //Fixed for demo purpose and testing, need new api-endpoint to get the list of availible options to be made funcional
        PaymentMethodId: 2,
        PaymentTermId: 1,
        ShippingTermId: 5,
        ShippingMethodId: 2,
        SendTypeId: 1,
        CurrencyId: 5,

        ShortName: values.ShortName,
        Description: values.Description,
        Disabled: values.Disabled,
        DropShipEnabled: values.DropShipEnabled,
        Email: values.Email,
        Contact: values.Contact,
        Fax: values.Fax,
        Mobile: values.Mobile,
        Name: values.Name,
        OurCustomerNumber: values.OurCustomerNumber,
        Phone: values.Phone,
        Web: values.Web,
        StandardLeadTime: values.StandardLeadTime,
        "Address": {
            "Company": "Testföretaget Raka Puckar",
            "Department": "Ekonomiavdelningen",
            "Title": null,
            "Salutation": null,
            "FirstName": "Christer",
            "MiddleNameInitial": null,
            "LastName": "Larsson",
            "Street": "Blomstigen 1",
            "StreetAdditionalInformation": null,
            "Postcode": 57393,
            "City": "Tranås",
            "County": null,
            "State": null,
            "Country": {
                "Key": 196,
                "Value": "SWEDEN"
            },
            "AdditionalInformation": null,
            "DoorCode": null,
            "Contact": null,
            "EmailAddress": "christer.lasseman@svedala.se",
            "PhoneNumber": null,
            "MobileNumber": null,
            "FaxNumber": null,
            "WebAddress": null
        }
    }
    return supplier;
}


export const supplierProductPostRequest = (values): AxiosRequestConfig => {
    let product = mapProductFormValuesToSupplierProductCreate(values);
    const request = createOrUpdateSupplierProduct(product);
    return request;
};

export const supplierProductPutRequest = (values): AxiosRequestConfig => {
    const product = mapProductFormValuesToSupplierProductUpdate(values);
    const request = createOrUpdateSupplierProduct(product, "PATCH", values.SupplierAssortmentItemId);
    return request;
};



export const supplierPostRequest = (values): AxiosRequestConfig => {
    let supplier = mapFormValuesToSupplier(values);
    console.log(supplier)
    const request = createOrUpdateSupplier(supplier);
    return request;
};

export const supplierPutRequest = (values): AxiosRequestConfig => {
    const supplier = mapFormValuesToSupplier(values);
    console.log(supplier)
    const request = createOrUpdateSupplier(supplier, "PATCH", values.Id);
    return request;
};