import {  GridColDef, GridColumns } from '@mui/x-data-grid';
import React from 'react';
import { tableSettingsProps, useTableSettings } from './useTableSettings';
import tableHelper from 'src/utils/tables/tableHelper';
import { useGetAllWarehouseStock, useGetWarehouseLocationsById, useGetWarehouseStockById, useGetWarehouses } from '../services/bold/warehouse';


export const useWarehousesTable = (): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetWarehouses();
  console.log(rows)
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'WarehouseId', headerName: 'WarehouseId', },
      { field: 'WarehouseName', headerName: 'WarehouseName', flex:1 },
      { field: 'WarehouseType', headerName: 'WarehouseType',flex:1 },
      { field: 'City', headerName: 'City', flex:1},
      { field: 'Country', headerName: 'Country', flex:1},
      { field: 'IsExternal', headerName: 'IsExternal',width:50, type:"boolean" },
      { field: 'IsActive', headerName: 'IsActive',width:50, type:"boolean" },
    ],
    [],
  );
  const tableName = "WarehousesTable";
  const tableSettings = useTableSettings(columns, rows,tableName);
  tableSettings.idRow = ["WarehouseId"];
  return [(rows ?? []), columns, tableSettings];
};




export const usePackingStationsRowsTable = (initialValues): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = initialValues?.PackingStations ?? [];
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      { field: 'Id', headerName: 'Id', type:"number"},
      { field: 'DeliveryNotePrinterId', headerName: 'DeliveryNotePrinterId'},
      { field: 'Name', headerName: 'Name', flex:1},
      { field: 'ComputerName', headerName: 'ComputerName', flex:1},
      { field: 'WaybillPrinterId', headerName: 'WaybillPrinterId', type:"number"},


    ],
    [initialValues],
  );
  const tableSettings = useTableSettings(columns, rows, "PackingStationsRowsTable");
  tableSettings.autoHeight = true;
  tableSettings.hasToolbar=false;
  
  return [(tableSettings?.newRows ?? []), columns, tableSettings];
};

export const useReceivingStationsRowsTable = (initialValues): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = initialValues?.ReceivingStations ?? [];
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      { field: 'Id', headerName: 'Id', type:"number"},
      
      { field: 'Name', headerName: 'Name', flex:1},
      { field: 'ComputerName', headerName: 'ComputerName', flex:1},
      { field: 'LabelPrinterName', headerName: 'LabelPrinterName', flex:1},
      { field: 'LabelPrinterId', headerName: 'LabelPrinterId', type:"number"},
      { field: 'ReceivingLocationName', headerName: 'ReceivingLocationName', flex:1},
      { field: 'ReceivingLocationId', headerName: 'ReceivingLocationId', type:"number"},


    ],
    [initialValues],
  );
  const tableSettings = useTableSettings(columns, rows, "PackingStationsRowsTable");
  tableSettings.autoHeight = true;
  tableSettings.hasToolbar=false;
  
  return [(tableSettings?.newRows ?? []), columns, tableSettings];
};


export const useWarehouseLocationsTable = (editId): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetWarehouseLocationsById(editId);
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      { field: 'Id', headerName: 'Id',  type:"number" },
      { field: 'Label', headerName: 'Label', flex:1 },
      { field: 'WarehouseId', headerName: 'WarehouseId',  type:"number" },
      { field: 'PartitionId', headerName: 'PartitionId',  type:"number" },
      
      
      
    ],
    [],
  );
  const tableName = "WarehouseLocationsTable";
  const tableSettings = useTableSettings(columns, rows,tableName);
  tableSettings.hasToolbar=false;
  return [(rows ?? []), columns, tableSettings];
};
export const useWarehouseStocksTable = (editId): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetWarehouseStockById(editId);
  console.log(rows)
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      { field: 'SalesItemsId', headerName: 'SalesItemsId',  type:"number" },
      { field: 'ArticleNumber', headerName: 'ArticleNumber',  type:"number" },
      { field: 'WarehouseQty', headerName: 'WarehouseQty',  type:"number", flex:1 },
      { field: 'AvailableQty', headerName: 'AvailableQty',  type:"number" },
      { field: 'OrderFulfillmentReservedQty', headerName: 'OrderFulfillmentReservedQty',  type:"number" },
      { field: 'TransferOrderReservedQty', headerName: 'TransferOrderReservedQty',  type:"number" },
      
      
      
    ],
    [],
  );
  const tableName = "WarehouseStockTable";
  const tableSettings = useTableSettings(columns, rows,tableName);
  tableSettings.idRow=["ArticleNumber"];
  tableSettings.hasToolbar=false;
  return [(rows ?? []), columns, tableSettings];
};
