import { IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';

const TooltipField: FC<{ tooltipText?: string }> = ({ tooltipText }) => {
  return (
    tooltipText?(
      <Tooltip
        arrow
        placement = "top-end"
        title = {
        tooltipText
      }
        >
        <IconButton
          size="small"
          sx={{
            ml: 1
          }}
          color="primary"
        >
          <HelpOutlineTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip >
    ) : <></>
  )
}

export default TooltipField;