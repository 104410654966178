import { useLocation } from "react-router-dom";

interface BreadcrumbProps {
  label: string[]
  path: string[]
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const useBreadcrumbs = (): BreadcrumbProps => {
  const location = useLocation();
  const pathParts = location.pathname.split('/').filter((part) => part?.trim() !== '');
  const breadcrumbs: BreadcrumbProps = { label: ["Home"], path: ["/"] }
  pathParts?.map((part, partIndex) => {
    const previousParts = pathParts.slice(0, partIndex);
    const pageName = capitalizeFirstLetter(part.replace('-', ' '));
    breadcrumbs.label.push(pageName);
    breadcrumbs.path.push(previousParts?.length > 0 ? `/${previousParts?.join('/')}/${part}` : `/${part}`);
  })
  return breadcrumbs;
};
export default useBreadcrumbs;