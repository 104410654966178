import { AxiosRequestConfig, Method } from "axios";
import { PaginationResult } from "src/interfaces/bold/pagination";
import { CategoryResponse, CreateOrUpdateSalesItemRequest, ImagesResponse,  SalesItemListItem, SalesItemModel, SalesItemsByCategoryRequest, SalesItemsSearchCreteria } from "src/interfaces/bold/salesitem/salesitem";
import { createOrUpdateSalesItem, getAllSalesItems, getCategories, getImagesBySku, getPriceListsBySky, getProductInfoBySku, getSalesItemAttributesBySku, getSalesItemById, getSalesItemBySku, getSalesItems, getSalesItemsByCategory, getSalesItemsBySearchParams, postPriceListBySku, removePriceListBySku } from "src/utils/services/bold/requests";
import useGet from "../useGet";
import useGetAllPaginatedItems from "../useGetAllPaginatedItems";
import { useResponse } from "../useResponse";

export const useGetSalesItemById = (id: number): SalesItemModel => {
    const [salesItem] = useResponse<SalesItemModel>(getSalesItemById, { id }, true);
    return salesItem;
}

export const useGetSalesItemBySku = (sku: string): SalesItemModel => {
    const [salesItem] = useResponse<SalesItemModel>(getSalesItemBySku, { sku }, true);
    return salesItem;
}

export const useGetSalesItems = (sc: SalesItemsSearchCreteria) => {
    const [salesItems] = useResponse<PaginationResult<SalesItemListItem>>(getSalesItems, sc)
    return salesItems;
}


export const useGetSalesItemsByCategory = (si: SalesItemsByCategoryRequest): PaginationResult<SalesItemListItem> => {
    const [salesItems] = useResponse<PaginationResult<SalesItemListItem>>(getSalesItemsByCategory, si)
    return salesItems;
}




export const useGetAllSalesItems = () => {
    return useGetAllPaginatedItems<SalesItemListItem[]>(getAllSalesItems, 500, "ArticleNumber");
}

export const useCreateOrUpdateSalesItem = (salesItem: CreateOrUpdateSalesItemRequest, method: Method = "POST", id: number = null) => {
    const request = createOrUpdateSalesItem(salesItem, method, id);
    const result = useGet<SalesItemModel>(request);
    return result;
}
export const useProductInfo = (sku: string, languageId?: number) => {
    const [productInfo] = useResponse<any>(getProductInfoBySku, { sku, languageId }, true, languageId);
    return productInfo;
}
export const useGetSalesItemAttributes = (sku: string) => {
    const [salesItemAttributes] = useResponse<any>(getSalesItemAttributesBySku, { sku }, true);
    return salesItemAttributes;
}

export const useProductImages = (sku: string) => {
    const [images] = useResponse<ImagesResponse[]>(getImagesBySku, { sku }, true);
    return images;
}
export const usePriceListBySky = (sku: string) => {
    const [priceList] = useResponse<any>(getPriceListsBySky, { sku }, true);
    return priceList;
}

export const useUpdatePriceListBySku = (priceListPost) => {
    const sku = priceListPost?.sku;
    const newPriceLists = priceListPost?.priceList;
    const request = postPriceListBySku(sku, newPriceLists);
    const [result] = useGet(request, newPriceLists );
    return result;
}

export const usRemovePriceListBySku = (priceListPost) => {
    const sku = priceListPost?.sku;
    const removePriceLists = priceListPost?.priceList;
    const request = removePriceListBySku(sku, removePriceLists);
    const [result] = useGet(request, removePriceLists );
    return result;
}


export const useGetCategories = () => {
    const [categories] = useResponse<CategoryResponse[]>(getCategories);
    return categories;
}
