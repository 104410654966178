import { getAllTransferOrders,  getAllWarehouseStock, getReturnOrderById, getTransferOrderById, getTransferOrderReferenceData, getWarehouseById, getWarehouseLocationsById, getWarehouseStockById, getWarehouses } from "src/utils/services/bold/requests";
import { useResponse } from "../useResponse";
import useGetAllPaginatedItems from "../useGetAllPaginatedItems";
import { useMemo } from "react";

export const useGetWarehouseById = (id:string) => {
    const [response] = useResponse<any[]>(getWarehouseById,{id}, true);
    return response;
}
export const useGetWarehouseLocationsById = (id:string) => {
    const [response] = useResponse<any[]>(getWarehouseLocationsById,{id}, true);
    return response;
}
export const useGetWarehouseStockById = (id: string | string[]) => {
    return useGetAllPaginatedItems<any>(getWarehouseStockById, 1000, "ArticleNumber", id);
};



export const useGetAllWarehouseStock = () => {
    return useGetAllPaginatedItems<any>(getAllWarehouseStock, 1000, "ArticleNumber");
    
}
export const useGetWarehouses = () => {
    const [response] = useResponse<any[]>(getWarehouses);
    return response;
}

export const useGetAllTransferOrders = () => {
    return useGetAllPaginatedItems<any>(getAllTransferOrders, 500, "OrderId");
    
}
export const useGetTransferOrderById = (id:string) => {
    const [response] = useResponse<any[]>(getTransferOrderById,{id}, true);
    return response;
}
export const useTransferOrderReferenceData = () => {
    return useResponse<any>(getTransferOrderReferenceData);
}


