import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "../axios";

export const callApi = (request: AxiosRequestConfig<any>, isMountedRef, setResults, setMessageContext,  callback?: <T = any, >(responseData: T) => T | any, abortSignal?: AbortSignal) => {
    const abortController = new AbortController();
 
    if(request != null){request.signal = abortSignal ?? abortController?.signal}
    //console.log(request.url)
    axiosInstance(request)

        .then((response) => {
            if (!isMountedRef()) {
                return;
            }

            if (response.data) {
                setResults(response.data);
            } else {
                // console.log(response.statusText);
                setMessageContext(response.statusText, "warning")
            }
            if (callback) {
                callback(response.data);
            }
        })
        .catch((err) => {
            const errorMessage = err.response?.data ?? err.message;
            // console.log(errorMessage);
            setMessageContext(errorMessage, "error")
        });

    return () => {
        abortController.abort();
    }
}



export const shouldRequest = (
    requestNothingOnNull: boolean, 
    requestFunctionValues: any[]
  ): boolean => {
    // console.log(requestNothingOnNull,requestFunctionValues)
    if (requestNothingOnNull) {
      const firstValue = requestFunctionValues[0];
    //   console.log(firstValue)
      if (
        !requestFunctionValues.length || 
        firstValue === "" || 
        firstValue === null || 
        firstValue === undefined || 
        Number.isNaN(firstValue)
      ) {
        //console.log("Returning false")
        return false;
      }
    }
    return true;
  };
  