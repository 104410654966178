import { Button, CircularProgress, Dialog, DialogContent, Grid, MenuItem, TextField } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { DataGridPro, DataGridProProps, gridClasses, GridColumnMenuContainer, GridColumnMenuProps, GridColumnsMenuItem, GridEventListener, GridFilterMenuItem, GridLocaleText, GridPinnedColumns, GridRenderCellParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter, HideGridColMenuItem, SortGridMenuItems, useGridApiContext } from "@mui/x-data-grid-pro";
import React, { useState, useEffect } from "react";
import { CSSProperties } from "react";
import { defaultTableDensity, tableSettingsProps } from "src/hooks/table/useTableSettings";
import RestoreIcon from '@mui/icons-material/Restore';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { ContentCard } from "./ContentCard";
import CustomButton from "../atoms/buttons/CustomButton";
import { useAi } from "src/hooks/useAi";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { GridColumnsPanel, GridSlotsComponentsProps, GridToolbarExport } from '@mui/x-data-grid';
import { CustomField } from "../molecules/formik/CustomField";
import { useSettingsContext } from "src/hooks/useUserPrefs";
import { getNestedValue } from "src/utils/settings";
import { CustomColumnsPanel } from "../molecules/table/CustomColumnsPanel";
import { BarcodeScannerButton } from "../molecules/BarcodeScanner";
import useBarcodeScanner from "src/hooks/useBarcodeScanner";

export interface CustomDataGridProps extends DataGridProProps {
    wrapperStyle?: CSSProperties,
    tableSettings?: tableSettingsProps,
}


export const CustomDataGrid = ({
    wrapperStyle = { height: "max(85vh, 400px)", width: '100%' },
    tableSettings,
    ...dataGridProps
}: CustomDataGridProps) => {
    const onColumnHeaderDragEnd: GridEventListener<'columnHeaderDragEnd'> = (params) => {
        //When implemented in MUI, get column ordering and save it as setting
    };

   

    return (
        <div style={tableSettings?.customWrapperStyle == null ? (tableSettings?.autoHeight ? { height: "auto", width: '100%' } : wrapperStyle) : tableSettings?.customWrapperStyle}>
            {/* <ErrorBoundary> */}

            <StripedDataGrid
                localeText={myLocaleText}
                treeData={tableSettings?.treeData ?? false}
                editMode="row"
                rowHeight={tableSettings?.density ?? defaultTableDensity}
                apiRef={tableSettings?.apiRef}
                columnVisibilityModel={tableSettings?.columnVisibilityModel}
                disableSelectionOnClick={tableSettings?.disableSelectionOnClick ?? false}
                onSelectionModelChange={(newModel) => {
                    if (tableSettings?.selectOnlyOneRow) {
                        if (newModel?.length > 1) {
                            const selectionSet = new Set(tableSettings?.rowSelectionModel);
                            const result = newModel.filter((s) => !selectionSet.has(s));
                            tableSettings?.setRowSelectionModel(result);
                        } else {
                            tableSettings?.setRowSelectionModel(newModel);
                        }
                    } else {
                        tableSettings?.setRowSelectionModel(newModel)
                    }


                }}
                pinnedColumns={tableSettings?.pinnedColumns ?? {}}
                onPinnedColumnsChange={React.useCallback(
                    (updatedPinnedColumns: GridPinnedColumns) => {
                        tableSettings?.setPinnedColumns(updatedPinnedColumns);
                    },
                    [])}
                selectionModel={tableSettings?.rowSelectionModel}
                hideFooterRowCount={tableSettings?.hideFooterRowCount ?? false}
                hideFooter={tableSettings?.hideFooter ?? false}
                autoHeight={tableSettings?.autoHeight ?? false}
                getTreeDataPath={(row) => row.path}
                checkboxSelection={tableSettings?.checkboxSelection ?? false}
                //isGroupExpandedByDefault={() => { return tableSettings?.defaultExpanded ?? false}}
                defaultGroupingExpansionDepth={tableSettings?.defaultGroupingExpansionDepth ?? 3}
                groupingColDef={tableSettings?.groupingColDef ?? null}
                sx={{
                    ...DataGridStyling,
                    //"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": { display: tableSettings?.selectOnlyOneRow ? "none" : "block" }
                }}
                onColumnOrderChange={onColumnHeaderDragEnd}
                loading={tableSettings?.loading ?? false}
                components={{
                    Toolbar: (tableSettings?.hasToolbar ?? false) ? () => CustomToolbar(tableSettings) : null,
                    // Toolbar: () => <Grid container justifyContent={"left"}> <Grid item xs={2}><ActionButton title="Expand"></ActionButton></Grid></Grid>,
                    LoadingOverlay: noRowsSpinner,
                    ColumnMenu: tableSettings?.customColumnMenu ?? DefaultColumMenu,
                    //Footer: () => <GridFooter/>,
                    ColumnsPanel: CustomColumnsPanel,
                    NoRowsOverlay: (tableSettings?.noRowsMessage ?? NoRows),
                }}
                componentsProps={{
                    columnsPanel: {
                        tableSettings: tableSettings,
                        
                    },

                }}
                getDetailPanelHeight={() => 'auto'}
                getRowId={(row) => {
                    return (tableSettings.idRow?.length == 2 ? row[tableSettings?.idRow[0]][tableSettings?.idRow[1]] : row[tableSettings?.idRow[0]]) ?? row.id;
                }
                }

                initialState={{
                    filter: {
                        filterModel: {
                            items: [tableSettings?.initalFilter ?? { columnField: "", operatorValue: "", value: "" }],
                            quickFilterValues: [],
                        },
                    },
                    sorting: {
                        sortModel: tableSettings?.initalSorting ?? null,
                    },

                }}
                onColumnVisibilityModelChange={(newModel) =>
                    tableSettings?.setColumnVisibilityModel(newModel)
                }
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                {...dataGridProps}
            />

            {/* </ErrorBoundary> */}
        </div>
    );
}




function CustomToolbar(tableSettings) {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter></GridToolbarQuickFilter>
            
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {tableSettings?.checkboxSelection ?
                <MultiEditButton tableSettings={tableSettings}></MultiEditButton>
                : null}

            <Button
                disabled={!tableSettings?.hasUnsavedRows || tableSettings?.isSaving}
                onClick={() => {
                    tableSettings?.discardChanges()
                }}
                startIcon={<RestoreIcon />}
            >
                Discard all changes
            </Button>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const MultiEditButton = ({ tableSettings }) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };
    return (
        <>
            <Button
                disabled={!tableSettings?.rowSelectionModel?.length}
                onClick={handleClickOpen}
                startIcon={<AppRegistrationIcon />}
            >
                Edit Multiple Rows
            </Button>
            <MultiEditPopup

                open={open}
                onClose={handleClose}
                tableSettings={tableSettings}
            />
        </>
    );
};

const MultiEditPopup = ({ open, onClose, tableSettings }) => {
    const identifier = tableSettings?.idRow?.join("_");
    const [columnKey, setColumnKey] = useState("Name")
    const baseInstructions = "I want the product names to make it sound like slightly more expensive or premium version of there product names"
    const [instructions, setInstructions] = useState(baseInstructions)
    const [responses, generateResponse] = useAi()
    const selectedRows = tableSettings?.newRows?.filter(row => tableSettings.rowSelectionModel.includes(row[identifier]));

    const generateInstructions = () => {
        const base = "Generate new values for each item in the list below. I want updated/modified values of type " + columnKey + " according to these instruction: "
        const valuesAsList = "[" + selectedRows?.map((row, index) => ("{" + columnKey + ":'" + row[columnKey] + "'}")) + "]"
        const completeInstructions = base + " \n \n" + instructions + ".\n Here is the list that should be used as base, for each one generate only one new modified value of type " + columnKey + " : \n \n" + valuesAsList;

        const testingInstructions = `
        For each item in this list, give me new more SEO optimised and interesting names, use the attributes when applicable or add some generic and always true additional descriptor. Do not make the names too long, they should look good on a website. 

        [
            {
              "name": "Basic Chair",
              "color": "Red",
              "material": "Wood",
              "height": "90cm",
              "width": "40cm",
              "weight": "5kg",
              "brand": "SitEasy"
            },
            {
              "name": "Classic Watch",
              "color": "Silver",
              "material": "Stainless Steel",
              "height": "1cm",
              "width": "3cm",
              "weight": "0.1kg",
              "brand": "Timeless"
            },
            {
              "name": "Running Sneakers",
              "color": "Blue",
              "material": "Mesh Fabric",
              "height": "10cm",
              "width": "8cm",
              "weight": "0.3kg",
              "brand": "QuickStep"
            },

          ]
          `

        //   {
        //     "name": "Portable Speaker",
        //     "color": "Black",
        //     "material": "Plastic",
        //     "height": "15cm",
        //     "width": "7cm",
        //     "weight": "0.4kg",
        //     "brand": "SoundWave"
        //   },
        //   {
        //     "name": "Cotton T-Shirt",
        //     "color": "White",
        //     "material": "Cotton",
        //     "height": "70cm",
        //     "width": "50cm",
        //     "weight": "0.2kg",
        //     "brand": "ComfyCotton"
        //   },
        //   {
        //     "name": "Ceramic Vase",
        //     "color": "Green",
        //     "material": "Ceramic",
        //     "height": "25cm",
        //     "width": "10cm",
        //     "weight": "1kg",
        //     "brand": "AncientDecor"
        //   },
        //   {
        //     "name": "Leather Wallet",
        //     "color": "Brown",
        //     "material": "Leather",
        //     "height": "9cm",
        //     "width": "11cm",
        //     "weight": "0.08kg",
        //     "brand": "LuxPockets"
        //   },
        //   {
        //     "name": "Stainless Steel Water Bottle",
        //     "color": "Pink",
        //     "material": "Stainless Steel",
        //     "height": "22cm",
        //     "width": "7cm",
        //     "weight": "0.35kg",
        //     "brand": "HydroKeep"
        //   }
        console.log(testingInstructions)
        generateResponse(testingInstructions)

    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>

            <ContentCard contentPadding={0} cardId="Edit Selected Rows"
                components={[

                    { component: <CustomButton disabled={responses?.length < 1} title="Accept" onClick={onClose}></CustomButton> },
                    { component: <CustomButton title="Generate" icon={<SmartToyIcon></SmartToyIcon>} onClick={generateInstructions}></CustomButton> },

                ]}>
                <DialogContent>

                    <TextField label={"Column "} fullWidth={true} onChange={(event) => { setColumnKey(event.target.value) }} select={true} value={columnKey}>
                        <MenuItem value="Name">Name</MenuItem>
                        <MenuItem value="Height">Height</MenuItem>
                    </TextField>
                    <br></br>
                    <br></br>
                    <TextField label={"Instructions for Edit"} fullWidth={true} onChange={(event) => { setInstructions(event.target.value) }} value={instructions}>
                    </TextField>
                    <br></br>
                    <br></br>
                    <br></br>
                    {JSON.stringify(responses)}
                    <br></br>
                    <br></br>
                    {/* Render your table or list here with the selectedRows data */}
                    <ul>
                        {selectedRows?.map((row, index) => (
                            <li key={index}>
                                {row[columnKey]}
                            </li>
                        ))}
                    </ul>
                </DialogContent>


            </ContentCard>

        </Dialog >
    );
};


export const DefaultColumMenu = React.forwardRef<
    HTMLUListElement,
    GridColumnMenuProps
>(function GridColumnMenu(props: GridColumnMenuProps, ref) {
    const { hideMenu, currentColumn } = props;

    return (
        <GridColumnMenuContainer ref={ref} {...props}>
            <SortGridMenuItems onClick={hideMenu} column={currentColumn!} />
            <GridFilterMenuItem onClick={hideMenu} column={currentColumn!} />
            <HideGridColMenuItem onClick={hideMenu} column={currentColumn!} />
            <GridColumnsMenuItem onClick={hideMenu} column={currentColumn!} />
            {/* <ActionButton variant="text" title="Expand all Categories"></ActionButton> */}
        </GridColumnMenuContainer>
    );
});


const noRowsSpinner = () => {
    return (
        <Grid container sx={{ height: "300px", maxHeight: "100%" }} display={"flex"} justifyContent={"center"} alignContent={"center"}  >
            <Grid md={1} item>
                <CircularProgress data-testid="TableLoading" size={40} />
            </Grid>
        </Grid>
    );
}

export const NoRows = () => {
    return (
        <Grid container sx={{ height: "300px", maxHeight: "100%" }} display={"flex"} justifyContent={"center"} alignContent={"center"}  >
            <Grid md={1} item>
                No rows
            </Grid>
        </Grid>
    );
}

export interface attributeToUpdateProps {
    Id: string | number,
    Value?: string | number,
    ValueId?: string | number,
    UnitId?: string | number,
}


export const AttibuteInputCell = ({
    params,
    attributes,
    attributesToUpdate,
    setAttributesToUpdate
}: {
    params: GridRenderCellParams,
    attributes: any[],
    attributesToUpdate: attributeToUpdateProps[],
    setAttributesToUpdate: React.Dispatch<React.SetStateAction<attributeToUpdateProps[]>>,
}) => {
    const apiRef = useGridApiContext();
    console.log(attributes)
    const currentAttribute = attributes?.find(atr => atr.Id == params.row.Attribute.Key)
    const updateAttributesToUpdate = (attribute: any) => {
        const existingAttributeIndex = attributesToUpdate.findIndex(atr => atr?.Id == attribute.Id);
        let enumId = null;
        if (currentAttribute?.Enumerated) {
            enumId = currentAttribute.EnumerationValues.find(enumVal => enumVal?.Value == attribute.Value)?.ValueListItemId;
        }
        const postableAttribute = { ...attribute, ValueId: enumId, UnitId: currentAttribute?.UnitId ?? null }
        if (existingAttributeIndex === -1) {
            setAttributesToUpdate([...attributesToUpdate, postableAttribute])
        } else {
            let newAttributesToUpdate = [...attributesToUpdate]
            newAttributesToUpdate[existingAttributeIndex] = postableAttribute;
            setAttributesToUpdate([...newAttributesToUpdate])
        }
    }
    const dataType = currentAttribute?.PropertyDataTypeId;
    let enumerationValues = currentAttribute?.EnumerationValues
    let isSelect = false;
    let isBoolean = false;
    let isNumber = false;
    if (currentAttribute?.Enumerated) {//Enumeration, Select dropwdown
        isSelect = true;
    }
    if (dataType == 4) {//Boolean
        isSelect = true;
        isBoolean = true;
        enumerationValues = [{ Value: "true" }, { Value: "false" }];
    }
    if (dataType == 5 || dataType == 7) {//Numbers, Decimal 5 and Integer 7
        isNumber = true;
    }
    const id = params.id;
    const value = isBoolean ? (params.value ?? "false") : params.value ?? "";
    const field = params.field;
    const handleChange = async (event: any) => {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        updateAttributesToUpdate({ Id: id, Value: event.target.value })
        if (isSelect) {
            apiRef.current.stopCellEditMode({ id, field });
        }
    };
    return (
        <TextField
            value={value} onChange={handleChange}
            select={isSelect ?? false}
            type={isNumber ? "number" : "text"}
            sx={{
                "& fieldset": { border: 'none' },
            }}
            size="small" variant="outlined"
            fullWidth autoFocus
        >
            {isSelect ? (enumerationValues?.map((option, index) => {
                return <MenuItem key={index} value={option.Value}>{(option.Value)}</MenuItem>
            })) : null}
        </TextField>
    );
}





const DataGridStyling = {
    boxShadow: 2,
    borderRadius: 0,
    border: 0,
    '& .MuiDataGrid-cell:hover': {
        color: 'primary.main',
    },
    '.MuiDataGrid-row--lastVisible .MuiDataGrid-cell--withRenderer': {
        overflow: 'visible !important', //IF Selected after demo, make better css-selection and tableSetting for this
        //paddingLeft:"40%",
    },

    '& .MuiDataGrid-columnHeader': {
        backgroundColor: `rgba(34, 51, 84, 0.02)`,
        borderRadius: 0,
    },
    '& .status.red': {
        color: 'red',
    },
    '& .status.green': {
        color: 'green',
    },
    '& .status.grey': {
        color: 'grey',
    },
    '.MuiDataGrid-detailPanel .even': {
        backgroundColor: '#ece6d6 !important',
    },


};

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ treeData, theme }) => (
    {
        [`& .${gridClasses.row}.even${treeData ? "disabled" : ""}`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },

            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
    }

));



const myLocaleText = {
    // ... other translations
    filterOperatorImageIdFilter: 'Filter by Image ID', // The display text for your filter
    filterOperatorThumbnailIdFilter: 'Filter by Thumbnail ID', // The display text for your filter
} as Partial<GridLocaleText>;

