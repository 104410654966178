import * as Yup from 'yup';
import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardTab, CardType, ContentCard, HeaderTabs } from 'src/components/organisms/ContentCard';
import { Formik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { FormContainerMolecule } from 'src/components/molecules/formik/FormContainerMolecule';
import { FormField } from 'src/components/molecules/formik/FormField';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import useSubmit from 'src/hooks/forms/useSubmit';
import { supplierPostRequest, supplierPutRequest } from 'src/utils/form/submitSupplierProduct';
import FormHelper from 'src/utils/form/formHelper';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { useState } from 'react';
import { useReturnOrderOptions } from 'src/hooks/forms/useOptions';
import MenuItem from '@mui/material/MenuItem';
import { useReturnOrderRowsTable } from 'src/hooks/table/useReturnOrdersTable';
import { usePackingStationsRowsTable, useReceivingStationsRowsTable, useWarehouseLocationsTable, useWarehouseStocksTable } from 'src/hooks/table/useWarehousesTable';
import { useWarehouseForm } from 'src/hooks/forms/useForms';

const scheme = Yup.object().shape({
});

const defaultInitialValues = {
};

const EditWarehouse = () => {
    const [editId, PageName, currentSubmitFunction, , isEditPage] = FormHelper.getEditPageConfig("Warehouse", supplierPostRequest, supplierPutRequest);
    const initialValues = useWarehouseForm(editId, defaultInitialValues)
    const [returnUnitOptions, returnReasonOptions] = useReturnOrderOptions();
    const [packingstationRows, packingstationColumns, packingstationTableSettings] = usePackingStationsRowsTable(initialValues)
    const [locationsRows, locationsColumns, locationsTableSettings] = useWarehouseLocationsTable(editId)
    const [productsRows, productsColumns, productsTableSettings] = useWarehouseStocksTable(editId)
    const [receivingstationRows, receivingstationColumns, receivingstationTableSettings] = useReceivingStationsRowsTable(initialValues)
    console.log(initialValues)
    const [result, submit] = useSubmit(currentSubmitFunction);
    const tabs: CardTab[] = [

        { value: 'Products', label: 'Products' },
        { value: 'Receiving Stations', label: 'Receiving Stations' },
        { value: 'Packing Stations', label: 'Packing Stations' },
        { value: 'Locations', label: 'Locations' },
    ]
    const [currentTab, setCurrentTab] = useState<string>('Products');

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={scheme}
                onSubmit={submit}
                enableReinitialize
            >
                {(props): JSX.Element => (
                    <div>
                        <PageHeader  {...{
                            title: PageName,
                            subTitle: "Create and edit Warehouse",
                            components: [
                                { component: <CustomButton isSubmitting={props.isSubmitting} onClick={props.handleSubmit} title="Save" icon={<SaveIcon />} /> },
                                { isInGroupedMenu: true, component: <CustomButton variant="text" title="New Sales Order" icon={<AddTwoToneIcon />} actionUrl="/orders/orders/create" /> },
                            ],
                            alertData: { dataArray: (result ?? false) ? [result] : null }
                        }} />
                        <ContentCardsContainer >
                            <ContentCard {...{ cardId: "Base Info", md: 6 }}>
                                <FormContainerMolecule formikProps={props}>
                                    <FormField fieldProps={{ name: "WarehouseName", label: "WarehouseName", md: 6.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "WarehouseId", disabled: true, label: "WarehouseId", md: 4.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "OrganizationName", label: "OrganizationName", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "Address", label: "Address", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "LastStockTaking", label: "LastStockTaking", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "StockTakingInProgress", select: true, label: "StockTakingInProgress", md: 5.8 }} formikProps={props}>
                                        <MenuItem value="true">True</MenuItem>
                                        <MenuItem value="false">False</MenuItem>
                                    </FormField>
                                </FormContainerMolecule>
                            </ContentCard>
                            <ContentCard {...{ cardId: "Settings", md: 6 }}>
                                <FormContainerMolecule formikProps={props}>
                                    <FormField fieldProps={{ name: "IsPickupWarehouse", select: true, label: "IsPickupWarehouse", md: 5.8 }} formikProps={props}>
                                        <MenuItem value="true">True</MenuItem>
                                        <MenuItem value="false">False</MenuItem>
                                    </FormField>
                                    <FormField fieldProps={{ name: "IsStoreWarehouse", select: true, label: "IsStoreWarehouse", md: 5.8 }} formikProps={props}>
                                        <MenuItem value="true">True</MenuItem>
                                        <MenuItem value="false">False</MenuItem>
                                    </FormField>
                                    <FormField fieldProps={{ name: "IsActive", select: true, label: "IsActive", md: 5.8 }} formikProps={props}>
                                        <MenuItem value="true">True</MenuItem>
                                        <MenuItem value="false">False</MenuItem>
                                    </FormField>
                                    <FormField fieldProps={{ name: "IsExternal", select: true, label: "IsExternal", md: 5.8 }} formikProps={props}>
                                        <MenuItem value="true">True</MenuItem>
                                        <MenuItem value="false">False</MenuItem>
                                    </FormField>
                                    <FormField fieldProps={{ name: "CommInvoicePrinter[Name]", label: "CommInvoicePrinter", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "PickingListPrinter[Name]", label: "PickingListPrinter", md: 5.8 }} formikProps={props}></FormField>

                                    <FormField fieldProps={{ name: "CustomerAddressFormatted", label: "Address", md: 5.8 }} formikProps={props}></FormField>
                                </FormContainerMolecule>
                            </ContentCard>
                        </ContentCardsContainer>
                    </div>
                )}
            </Formik>
            <ContentCardsContainer >
                <ContentCard {...{
                    cardId: "TabContainer",
                    contentPadding: 0,
                    cardTypes: [CardType.tabContainer],
                    cardHeader: <HeaderTabs {...{ tabs, currentTab, setCurrentTab }} ></HeaderTabs>,
                    components: [
                        //{ isInGroupedMenu: true, component: <ActionButton target='blank' variant='text' title="New Supplier Product" icon={<AddTwoToneIcon />} actionUrl={"/products/supplier-products/create?productId=" + editId} />},
                    ],
                }}>

                    {currentTab === "Products" &&
                        <ContentCard {...{ cardId: "Products", cardTypes: [CardType.tab], contentPadding: 0, }}>
                            <CustomDataGrid rows={productsRows} columns={productsColumns} tableSettings={productsTableSettings}></CustomDataGrid>

                        </ContentCard>
                    }
                    {currentTab === "Receiving Stations" &&
                        <ContentCard {...{ cardId: "Receiving Stations", cardTypes: [CardType.tab], contentPadding: 0, }}>
                            <CustomDataGrid rows={receivingstationRows} columns={receivingstationColumns} tableSettings={receivingstationTableSettings}></CustomDataGrid>

                        </ContentCard>
                    }
                    {currentTab === "Packing Stations" &&
                        <ContentCard {...{ cardId: "Packing Stations", cardTypes: [CardType.tab], contentPadding: 0, }}>

                            <CustomDataGrid rows={packingstationRows} columns={packingstationColumns} tableSettings={packingstationTableSettings}></CustomDataGrid>
                        </ContentCard>
                    }
                    {currentTab === "Locations" &&
                        <ContentCard {...{ cardId: "Locations", cardTypes: [CardType.tab], contentPadding: 0, }}>

                            <CustomDataGrid rows={locationsRows} columns={locationsColumns} tableSettings={locationsTableSettings}></CustomDataGrid>
                        </ContentCard>
                    }
                </ContentCard>

            </ContentCardsContainer>



            <Footer />
        </>
    );
}

export default EditWarehouse;
