import { GridCellParams, GridColDef, GridColumnVisibilityModel, GridRowParams, GridActionsCellItem } from '@mui/x-data-grid';
import { Order } from "src/interfaces/enums";
import { initalFilterProps } from "src/interfaces/tables/tables";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomButton from "src/components/atoms/buttons/CustomButton";
import HistoryIcon from '@mui/icons-material/History';
import clsx from "clsx";
import RestoreIcon from '@mui/icons-material/Restore';


const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: ''
}

const buttonColumn = (params: GridRowParams, type: string, baseUrl?: string, keyName?: string, tooltipText?: string) => {
  const icon = type == "Edit" ? <EditIcon /> : (type == "Delete" ? <DeleteIcon /> : <VisibilityIcon />);
  const id = keyName.includes("_") ? (params.row[keyName.split("_")[0]]?.[keyName.split("_")[1]]) : params.row[keyName];
  return [
    <CustomButton key={params.row.Id}
      iconButton={true}
      icon={icon}
      toolTipTitle={tooltipText ?? type}
      actionUrl={baseUrl + id} />
    ,
  ]
}

function DiscardChangesButton(rowId, tableSettings, params, index) {

  if (!tableSettings?.hasUnsavedRows) {
    return <></>;
  }

  return (
    <GridActionsCellItem
      icon={<RestoreIcon />}
      label="Discard changes"
      key={index}
      disabled={!tableSettings?.unsavedChangesRef.current.find(row => row[rowId] == params.id)}
      onClick={() => {
       const originalRow = tableSettings?.originalRowsRef.current.find(row => row[rowId] === params.id);
        if (originalRow) {
          tableSettings?.apiRef.current.updateRows([originalRow]);
        }
         tableSettings.unsavedChangesRef.current = tableSettings?.unsavedChangesRef.current.filter(row => row[rowId] !== params.id);
        tableSettings.setHasUnsavedRows(tableSettings?.unsavedChangesRef.current.length > 0);
        tableSettings.updateChangedRowsSetting();
      }}
    />
  );
}



const revertButton = (params: GridRowParams, editedRowIds, getApiRef) => {
  const revertChanges = (rowId) => {
    console.log("Reverting")
    console.log(rowId)
    const apiRef = getApiRef();
    console.log(apiRef)
    if (apiRef && apiRef.current) {

      const editRowsModel = apiRef.current.getEditRowsModel();
      console.log(editRowsModel)
      delete editRowsModel[rowId];
      console.log(editRowsModel)
      apiRef.current.setEditRowsModel(editRowsModel);
    }
  };

  return (<div key={params.id}>{editedRowIds?.length > 0 ?
    <CustomButton key={params.row.Id}
      iconButton={true}
      disabled={!editedRowIds.includes(params.id.toString())}
      icon={<HistoryIcon></HistoryIcon>}
      toolTipTitle={"Undo changes"}
      onClick={() => {
        console.log("revertChanges(params.id)");
        revertChanges(params.id)
      }}
    /> : null} </div>)
}


const statusColumn = (params: GridCellParams<string>) => {
  if (params.value == null) {
    return '';
  }
  return clsx('status', {
    red: params.value == ("Inactive"),
    green: params.value == ("Active"),
    grey: params.value == ("Discontinued"),
  });
}



const currencyFormatter = (value, options) => {
  if (typeof value !== 'number') value = 0.0
  options = { ...defaultOptions, ...options }
  value = value.toFixed(options.significantDigits)

  const [currency, decimal] = value.split('.')
  return ` ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal} ${options.symbol}`
}

const getCurrency = ({ value }) => currencyFormatter(value, {})

const getDateColumn = ({ value }) => value && new Date(value);
const getSelectOptions = (data: any[], field: string) => {
  if (data != null && data != undefined) {
    return Array.from(new Set(data.map(data => data[field]))).filter(function (val) { return val !== null; });
  }
  return [];
}


const getFieldKeyOrValue = (params) => {
  const fieldAndType = params.field.split("_");
  const field = fieldAndType[0]
  const type = fieldAndType[1]
  const nested = fieldAndType[2]
  let value: any = "";
  if (params.row[field] != null) {
    if (params.row[field][type] != null) {
      if ((params.row[field][type][nested] != null)) {
        value = params.row[field][type][nested] || ''
      } else {
        value = params.row[field][type] || ''
      }

    } else {
      if (params.row[field] != null && (type == null || type == undefined)) {
        value = params.row[field] || ''
      }
    }
  }
  return value ?? "";
}

const getSelectOptionsFromKeyValuePair = (data: any[], field: string) => {
  if (data != null && data != undefined) {
    return Array.from(new Set(data.map(data => data[field].Value))).filter(function (val) { return val !== null; });
  }
  return [];
}

const getDataGridUrlFilter = (): initalFilterProps => {
  const queryString = require('query-string');
  const urlSearchParams = queryString.parse(location.search);
  const field = urlSearchParams?.field ?? null;
  const value = urlSearchParams?.value ?? null;
  const operator = urlSearchParams?.operator ?? 'contains';
  const searchFilter = { columnField: field, operatorValue: operator, value: value }
  return searchFilter;
}

const getInitalVisibleColumns = (headerColumns: GridColDef[]): GridColumnVisibilityModel => {
  let visibilityModel = {};
  Object.values(headerColumns).forEach(column => {
    visibilityModel = { ...visibilityModel, ...{ [column.field]: true } };
  });
  return visibilityModel;
}

function optionToLabel(optionsArray, row: any, fieldName: string): string {
  const fieldValue = row[fieldName];
  const matchedOption = optionsArray.find(option => option.value === fieldValue);
  return matchedOption ? matchedOption.label : "";
}


const applyFilters = (serachColumns, nameQuery: string, tableData?: any[]): any[] => {
  return tableData?.filter((tableItem) => {
    let matches = true;
    if (nameQuery) {

      let containsQuery = false;
      serachColumns.forEach((property) => {
        if (tableItem[property].toLowerCase().includes(nameQuery.toLowerCase())) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}



const applySort = (tableData: any[], order: Order, orderBy: string): any[] => {
  const sortedTableData = tableData?.sort(getComparator(order, orderBy))
  return sortedTableData;
};
const applyPagination = (
  tableData: any[],
  page: number,
  limit: number
): any[] => {
  return tableData?.slice(page * limit, page * limit + limit);
};
const applySorting = (serachColumns, order: Order, orderBy: string, nameQuery: string, tableData?: any[]): any[] => {
  const filteredTableData = applyFilters(serachColumns, nameQuery, tableData);
  const sortedTableData = applySort(filteredTableData, order, orderBy);
  return sortedTableData;
};

const deleteButton = (params: GridRowParams, setChangedRows, changedRows, tableSettings) => {
  return [
    <CustomButton key={params.row.Id}
      iconButton={true}
      icon={<DeleteIcon />}
      onClick={() => {
        setChangedRows([...removeWithId(changedRows, params.id, tableSettings.idRow)])
      }} />
    ,]
}
const removeWithId = (array, removeId, idFields) => {
  const indexToRemove = array.findIndex((obj) => idFields.length == 2 ? obj[idFields[0]][idFields[1]] === removeId : obj[idFields[0]] === removeId);
  console.log("indexToRemove " + indexToRemove + " removeId " + removeId)
  let newArray = [...array]
  if (indexToRemove > -1) {
    newArray.splice(indexToRemove, 1);
  }
  console.log(newArray)
  return newArray;
}



const TableHelper = {
  deleteButton,
  DiscardChangesButton,
  removeWithId,
  getSelectOptions,
  buttonColumn,
  statusColumn,
  applyPagination,
  applySorting,
  getInitalVisibleColumns,
  getDataGridUrlFilter,
  getSelectOptionsFromKeyValuePair,
  getFieldKeyOrValue,
  getCurrency,
  getDateColumn,
  revertButton,
  optionToLabel,
};

export default TableHelper;
