import { RouteObject } from 'react-router';
import Authenticated from 'src/components/molecules/authenticated/Authenticated';
import AccentSidebarLayout from 'src/components/organisms/sidebarMenu/AccentSidebarLayout';
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/atoms/SuspenseLoader';
import { ErrorBoundary } from 'src/components/atoms/ErrorBoundry';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  </Suspense>
);

const Orders = Loader(lazy(() => import('src/pages/orders/orders/Orders')));
const EditSalesOrder = Loader(lazy(() => import('src/pages/orders/orders/EditSalesOrder')));

const Returns = Loader(lazy(() => import('src/pages/orders/returns/Returns')));
const Transfers = Loader(lazy(() => import('src/pages/warehouse/transfers/Transfers')));
const EditReturnOrder = Loader(lazy(() => import('src/pages/orders/returns/EditReturnOrder')));
const Categories = Loader(lazy(() => import('src/pages/products/categories/Categories')));
const ImagesResources = Loader(lazy(() => import('src/pages/products/images-resources/ImagesAndResources')));
const Manufacturers = Loader(lazy(() => import('src/pages/products/manufacturers/Manfufacturers')));
const ProductTypes = Loader(lazy(() => import('src/pages/products/productTypes/ProductTypes')));
const SupplierProducts = Loader(lazy(() => import('src/pages/products/supplierProducts/SupplierProducts')));
const LowOrderPoints = Loader(lazy(() => import('src/pages/purchasing/lowOrderPoints/LowOrderPoints')));
const PurchaseOrderDraft = Loader(lazy(() => import('src/pages/purchasing/purchaseOrderDraft/PurchaseOrderDraft')));
const PurchaseOrder = Loader(lazy(() => import('src/pages/purchasing/purchaseOrder/PurchaseOrder')));
const EditPurchaseOrder = Loader(lazy(() => import('src/pages/purchasing/purchaseOrder/EditPurchaseOrder')));
const EditPurchaseOrderDraft = Loader(lazy(() => import('src/pages/purchasing/purchaseOrderDraft/EditPurchaseOrderDraft')));
const CustomerReturnOrders = Loader(lazy(() => import('src/pages/reports/customerReturnOrders/CustomerReturnOrders')));
const ProductRecieveReport = Loader(lazy(() => import('src/pages/reports/productRecieveReport/ProductRecieveReport')));
const CampaignOrderReport = Loader(lazy(() => import('src/pages/reports/campaignReports/campaignOrderReport/CampaignOrderReport')));
const CampaignSalesItemsSales = Loader(lazy(() => import('src/pages/reports/campaignReports/salesItemsSales/SalesItemsSales')));
const SalesItemsSales = Loader(lazy(() => import('src/pages/reports/salesReports/salesItemsSales/SalesItemsSales')));
const SalesOrderReport = Loader(lazy(() => import('src/pages/reports/salesReports/salesOrderReport/SalesOrderReport')));
const ProductsHomePage = Loader(lazy(() => import('src/pages/products/ProductsPagesOverview')));
const OrdersHomePage = Loader(lazy(() => import('src/pages/products/ProductsPagesOverview')));
const PurchasingHomePage = Loader(lazy(() => import('src/pages/purchasing/Purchasing')));
const ReportsHomePage = Loader(lazy(() => import('src/pages/reports/Reports')));

const Warehouse = Loader(lazy(() => import('src/pages/warehouse/Warehouse')));

// Product
const Products = Loader(lazy(() => import('src/pages/products/products/Products')));
const ProductsCreate = Loader(lazy(() => import('src/pages/products/products/EditProduct')));
const SalesItems = Loader(lazy(() => import('src/pages/products/salesItems/SalesItems')));
const SalesItemsCreate = Loader(lazy(() => import('src/pages/products/salesItems/EditSalesItem')));
const EditSupplierProduct = Loader(lazy(() => import('src/pages/products/supplierProducts/EditSupplierProduct')));
const Suppliers = Loader(lazy(() => import('src/pages/products/supplierProducts/Suppliers')));
const EditSupplier = Loader(lazy(() => import('src/pages/products/supplierProducts/EditSupplier')));


//Account 
const Register = Loader(lazy(() => import('src/pages/auth/register/Register')));
import Logout from 'src/pages/auth/Logout';
import EditWarehouse from './pages/warehouse/EditWarehouse';
import EditTransferOrder from './pages/warehouse/transfers/EditTransferOrder';
import Backorders from './pages/purchasing/backorders/Backorders';
import EditBackorder from './pages/purchasing/backorders/EditBackorder';

// Settings
const WebclientSettings = Loader(lazy(() => import('src/pages/settings/WebclientSettings2')));
const IntegrationSettings = Loader(lazy(() => import('src/pages/settings/IntegrationSettings')));

// Testing
const TestingOverView = Loader(lazy(() => import('src/pages/testing/test/Test')));
const IntegrationTesting = Loader(lazy(() => import('src/pages/testing/test/IntegrationTesting')));
const KalleTesting = Loader(lazy(() => import('src/pages/testing/kalle/Kalle')));
const KalleTesting2 = Loader(lazy(() => import('src/pages/testing/kalle/Kalle2')));
const ContentCardTemplate = Loader(lazy(() => import('src/pages/templates/card/ContentCardTemplate')));
const FormikTemplate = Loader(lazy(() => import('src/pages/templates/formik/FormikTemplate')));
const DataGridTemplate = Loader(lazy(() => import('src/pages/templates/dataGrid/DataGridTemplate')));
const ErikTesting = Loader(lazy(() => import('src/pages/testing/erik/Erik')));
const HannesTesting = Loader(lazy(() => import('src/pages/testing/hannes')));
const OlleTesting = Loader(lazy(() => import('src/pages/testing/olle/Olle')));
const ApiTesting = Loader(lazy(() => import('src/pages/testing/api/Api')));
const Api2Testing = Loader(lazy(() => import('src/pages/testing/api/Api2')));
const Api3Testing = Loader(lazy(() => import('src/pages/testing/api/Api3')));





const pagesRoutes = [

  {
    path: 'products',
    children: [
      {
        path: '',
        element: <ProductsHomePage />
      },
      {
        path: 'products',
        element: <Products />
      },

      {
        path: 'products',
        children: [
          {
            path: 'create',
            element: <ProductsCreate />
          },
          {
            path: 'edit',
            element: <ProductsCreate />
          },


        ]
      },
      {
        path: 'product-types',
        element: <ProductTypes />
      },

      {
        path: 'sales-items',
        element: <SalesItems />
      },
      {
        path: 'sales-items',
        children: [
          {
            path: "create/",
            element: <SalesItemsCreate />
          },
          {
            path: "edit/",
            element: <SalesItemsCreate />
          },




        ]
      },
      {
        path: "sales-items/:categories",
        element: <SalesItems />
      },
      {
        path: 'categories',
        element: <Categories />
      },
      {
        path: 'supplier-products',
        element: <SupplierProducts />,
      },
      {
        path: 'suppliers',
        element: <Suppliers />,
      },
      {
        path: 'suppliers',
        children: [
          {
            path: "create/",
            element: <EditSupplier />
          },
          {
            path: "edit/",
            element: <EditSupplier />
          },




        ]
      },
      {
        path: 'supplier-products',
        children: [
          {
            path: "create/",
            element: <EditSupplierProduct />
          },
          {
            path: "edit/",
            element: <EditSupplierProduct />
          },




        ]
      },
      {
        path: 'manufacturers',
        element: <Manufacturers />
      },
      {
        path: 'resources',
        element: <ImagesResources />
      },

    ]


  },
  {
    path: 'reports',
    children: [
      {
        path: '',
        element: <ReportsHomePage />
      },
      {
        path: 'campaign-reports',
        children: [
          {
            path: 'sales-order-report',
            element: <CampaignOrderReport />
          },
          {
            path: 'sales-items-report',
            element: <CampaignSalesItemsSales />
          },
        ]
      },
      {
        path: 'sales-reports',
        children: [
          {
            path: 'sales-order-reports',
            element: <SalesOrderReport />
          },
          {
            path: 'sales-items-sales',
            element: <SalesItemsSales />
          },
        ]
      },

      {
        path: 'product-recieve-report',
        element: <ProductRecieveReport />
      },
      {
        path: 'customer-return-order-report',
        element: <CustomerReturnOrders />
      },


    ]
  },
  {
    path: 'settings',
    children: [
      {
        path: '',
        element: <Navigate to="webclient" replace />
      },
      {
        path: 'webclient',
        element: <WebclientSettings />
      },

      {
        path: 'integrations',
        element: <IntegrationSettings />
      },


    ]
  },
  {
    path: 'accounts',
    children: [
      {
        path: 'register',
        element: (
          <Register />
        )
      },
      {
        path: 'logout',
        element: (
          <Logout />
        )
      },



    ]
  },

  {
    path: 'testing',
    children: [
      {
        path: '',
        element: <Navigate to="sales-items" replace />
      },

      {
        path: 'test',
        element: <TestingOverView />
      },
      {
        path: 'integration-test',
        element: <IntegrationTesting />
      },
      {
        path: 'kalle',
        element: <KalleTesting />
      },
      {
        path: 'kalle2',
        element: <KalleTesting2 />
      },
      {
        path: 'erik',
        element: <ErikTesting />
      },
      {
        path: 'hannes',
        element: <HannesTesting />
      },
      {
        path: 'olle',
        element: <OlleTesting />
      },
      {
        path: 'api',
        element: <ApiTesting />
      },
      {
        path: 'api2',
        element: <Api2Testing />
      },
      {
        path: 'api3',
        element: <Api3Testing />
      },
    ]
  },
  {
    path: 'templates',
    children: [
      {
        path: 'cards',
        element: <ContentCardTemplate />
      },
      {
        path: 'formik',
        element: <FormikTemplate />
      },
      {
        path: 'datagrid',
        element: <DataGridTemplate />
      },

    ]
  },
  {
    path: 'orders',
    children: [
      {
        path: '',
        element: <OrdersHomePage />
      },
      {
        path: 'orders',
        element: <Orders />
      },
      {
        path: 'orders',
        children: [
          {
            path: "create",
            element: <EditSalesOrder />
          },
          {
            path: "edit",
            element: <EditSalesOrder />
          },




        ]
      },
      {
        path: 'returns',
        element: <Returns />

      },
      {
        path: 'returns',
        children: [
          {
            path: "create",
            element: <EditReturnOrder />
          },
          {
            path: "edit",
            element: <EditReturnOrder />
          },




        ]
      },

    ]
  },
  {
    path: 'purchasing',
    children: [
      {
        path: '',
        element: <PurchasingHomePage />


      },

      {
        path: 'purchase-order',
        element: <PurchaseOrder />
      },
      {
        path: 'purchase-order',
        children: [
          {
            path: "create",
            element: <EditPurchaseOrder />
          },
          {
            path: "edit",
            element: <EditPurchaseOrder />
          },




        ]
      },
      {
        path: 'backorders',
        element: <Backorders />
      },
      {
        path: 'backorders',
        children: [
          {
            path: "create",
            element: <EditBackorder />
          },
          {
            path: "edit",
            element: <EditBackorder />
          },




        ]
      },
      {
        path: 'purchase-order-draft',
        element: <PurchaseOrderDraft />
      },
      {
        path: 'purchase-order-draft',
        children: [
          {
            path: "create",
            element: <EditPurchaseOrderDraft />
          },
          {
            path: "edit",
            element: <EditPurchaseOrderDraft />
          },




        ]
      },
      {
        path: 'low-order-points',
        element: <LowOrderPoints />
      },
      {
        path: 'suppliers',
        element: <Suppliers />
      },
    ]
  },

  {
    path: 'warehouse',
    children: [
      {
        path: '',
        element: <Warehouse />
      },
      {
        path: 'product-overview',
        element: <Warehouse />
      },
      {
        path: 'warehouses',
        element: <Warehouse />
      },
      {
        path: 'warehouses',
        children: [
          {
            path: "create",
            element: <EditWarehouse />
          },
          {
            path: "edit",
            element: <EditWarehouse />
          },




        ]
      },
      {
        path: 'transfers',
        element: <Transfers />
      },
      {
        path: 'transfers',
        children: [
          {
            path: "create",
            element: <EditTransferOrder />
          },
          {
            path: "edit",
            element: <EditTransferOrder />
          },




        ]
      },
    ]
  }

];







const DashboardDefault = Loader(lazy(() => import('src/pages/dashboard/Dashboard')));
const router: RouteObject[] = [

  {
    path: '',
    element: <AccentSidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards" replace />
      },
      {
        path: 'overview',
        element: <Navigate to="/dashboards" replace />
      }
    ]
  },

  {
    path: '',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        children: pagesRoutes
      },
      {
        path: 'dashboards',
        children: [
          {
            path: '',
            element: (
              <DashboardDefault />
            )
          }

        ]
      },

    ]
  }
];

export default router;
