import { Button } from "@mui/material";
import { GridColumnMenuContainer, GridColumnMenuProps, GridColumnsMenuItem, GridColumnVisibilityModel, GridFilterMenuItem, GridSelectionModel, HideGridColMenuItem, SortGridMenuItems } from "@mui/x-data-grid";
import { GridGroupingColDefOverride, GridGroupingColDefOverrideParams, GridPinnedColumns, useGridApiRef, GridSortModel } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import React, { CSSProperties, JSXElementConstructor, useEffect, useRef } from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { NoRows } from "src/components/organisms/CustomDataGrid";
import { initalFilterProps } from "src/interfaces/tables/tables";
import tableHelper from "src/utils/tables/tableHelper";
import useUpdateEffect from "../useUpdateEffect";
import { useSettingsContext,useUserEditsContext } from "../useUserPrefs";
import { getNestedValue } from "src/utils/settings";


export const defaultCompactDensity = 20;
export const defaultTableDensity = 45;
export const defaultComfortableDensity = 65;

export interface initialTableSettings {
  density?: number;
  newRows?: any,
  setDensity?: Dispatch<SetStateAction<number>>;
  groupingColDef?: null | GridGroupingColDefOverride<any> | ((params: GridGroupingColDefOverrideParams) => GridGroupingColDefOverride<any>);
  checkboxSelection?: boolean;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  idRow?: string[];
  apiRef?: React.MutableRefObject<GridApiPro>;
  treeData?: boolean;
  noRowsMessage?: JSXElementConstructor<any>;
  initalSorting?: null | GridSortModel;
  hideFooter?: boolean;
  hasToolbar?: boolean;
  hideFooterRowCount?: boolean;
  disableSelectionOnClick?: boolean;
  selectOnlyOneRow?: boolean;
  autoHeight?: boolean;
  customWrapperStyle?: null | CSSProperties;
  hasUnsavedRows?: boolean;
  unsavedChangesRef?: React.MutableRefObject<any[]>;
  changeCounter?: number;
  originalRowsRef?: React.MutableRefObject<any[]>;
  setHasUnsavedRows?:React.Dispatch<React.SetStateAction<boolean>>;
  updateChangedRowsSetting?: () => void;
  discardChanges?: () => void;
  
}

export interface tableSettingsProps extends initialTableSettings {

  columnVisibilityModel?: GridColumnVisibilityModel;
  setColumnVisibilityModel?: React.Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
  initalFilter?: initalFilterProps;
  defaultGroupingExpansionDepth?: number;
  defaultExpanded?: boolean | number;
  setdefaultExpanded?: React.Dispatch<React.SetStateAction<boolean | number>>;
  customColumnMenu?: React.ForwardRefExoticComponent<GridColumnMenuProps & React.RefAttributes<HTMLUListElement>>;
  pinnedColumns?: GridPinnedColumns;
  setPinnedColumns?: React.Dispatch<React.SetStateAction<GridPinnedColumns>>;
  rowSelectionModel?: GridSelectionModel;
  setRowSelectionModel?: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  tableName?: string;
}



export const useInitialTableSettings = (tableName = "Table", rows = []) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [settings,setSettings] = useSettingsContext();
  const [edits,setUserEdits] = useUserEditsContext();
  const [newRows, setNewRows] = useState(rows);
  const originalRowsRef = useRef(rows);
  originalRowsRef.current = rows;
  const apiRef = useGridApiRef();
  const changedRows = getNestedValue(edits, ["TableChangedRows",tableName],[]) ;
  const [hasUnsavedRows, setHasUnsavedRows] = React.useState(changedRows?.length > 0 ? true : false);
  const [changeCounter, setChangeCounter] = useState(0);
  const unsavedChangesRef = useRef<any[]>(changedRows);
  useUpdateEffect(() => {
    originalRowsRef.current = rows;
    setNewRows([...(rows ?? []), ...(changedRows ?? [])])
    if (changedRows?.length > 0) {
      setHasUnsavedRows(true)
    }
  }, [rows])

  const handleCellEditEnd = (params, event, details) => {
    const newRow = apiRef?.current.getRow(params.id);
    unsavedChangesRef.current = [...(unsavedChangesRef.current ?? []), newRow];
    setHasUnsavedRows(true);
    updateChangedRowsSetting();
    setChangeCounter(prev => prev + 1);
  };
  const updateChangedRowsSetting = () =>{
    setUserEdits(unsavedChangesRef.current,"TableChangedRows", tableName ?? "UnNamed")
  }
  const discardChanges = () =>{
    setUserEdits([],"TableChangedRows", tableName ?? "UnNamed")
    setHasUnsavedRows(false);
    unsavedChangesRef.current = []
    setNewRows([...(rows ?? [])])
  }

  useEffect(() => {
    if (apiRef && apiRef.current && typeof apiRef.current.subscribeEvent === 'function') {
      apiRef.current.subscribeEvent('rowEditStop', handleCellEditEnd);
    } else {
      console.warn('subscribeEvent function not found on apiRef.current');
    }
  }, [apiRef]);

  const [density, setDensity] = useState(settings?.TableDensity ?? defaultTableDensity);
  const tableSettings: initialTableSettings = {
    newRows: newRows,
    density: density,
    setDensity: setDensity,
    discardChanges:discardChanges,
    setHasUnsavedRows:setHasUnsavedRows,
    updateChangedRowsSetting:updateChangedRowsSetting,
    groupingColDef: null,
    checkboxSelection: false,
    loading: loading,
    originalRowsRef:originalRowsRef,
    setLoading: setLoading,
    idRow: ["Id"],
    apiRef: apiRef,
    treeData: false,
    noRowsMessage: NoRows,
    initalSorting: null,
    hideFooter: false,
    hasToolbar: true,
    hideFooterRowCount: false,
    disableSelectionOnClick: false,
    selectOnlyOneRow: false,
    autoHeight: false,
    customWrapperStyle: null,
    hasUnsavedRows: hasUnsavedRows,
    unsavedChangesRef: unsavedChangesRef,
    changeCounter: changeCounter
  }
  return tableSettings;
};

export const useCompleteTableSettings = (columns, initialTableSettings, tableName?) => {
  const {  setLoading, hasToggleMenu } = initialTableSettings;
  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({});
  const [settings, setSettings] = useSettingsContext();
  const visibilitySetting =  getNestedValue(settings, ["TableColumnVisibility",tableName], tableHelper.getInitalVisibleColumns(columns)) as unknown as GridColumnVisibilityModel;
  const expasionSettings = settings?.TableExpanded
  const [defaultGroupingExpansionDepth, setDefaultGroupingExpansionDepth] = useState((expasionSettings != undefined ? expasionSettings[tableName] : 3) as unknown as number)
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>(visibilitySetting);
  const updateColumnVisibilityModel = (newColumnVisibilityModel: GridColumnVisibilityModel) => {
    setSettings(newColumnVisibilityModel,"TableColumnVisibility", tableName ?? "UnNamed")
    setColumnVisibilityModel(newColumnVisibilityModel)
  }
  useUpdateEffect(() => {
    setLoading(false)
    setColumnVisibilityModel(visibilitySetting)
  }, [initialTableSettings.newRows])
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const updateRowSelectionModel = (newSelectionModel: GridSelectionModel) => {
    setRowSelectionModel(newSelectionModel)
  }
  const [defaultExpanded, setdefaultExpanded] = useState(true)
  const initalFilter = tableHelper.getDataGridUrlFilter();

  const toggleExpandRows = () => {
    const rowIds = tableSettings.apiRef?.current.getAllRowIds()
    if (rowIds.length > 1) {
      rowIds.forEach(rowId => {
        tableSettings.apiRef?.current.setRowChildrenExpansion(rowId, !tableSettings.apiRef?.current.getRowNode(rowId)?.childrenExpanded);
      })
    }
    const expasionSettings = settings?.TableExpanded
    const expanded = rowIds?.length > 0 ? tableSettings.apiRef?.current.getRowNode(rowIds[0])?.childrenExpanded : (expasionSettings[tableName] < 0 ? false : true)
    const newExpansionValue = (expanded ? -1 : 0)
    if (newExpansionValue != expasionSettings[tableName]) {
      setSettings(newExpansionValue,"TableExpanded", tableName ?? "UnNamed" )
    }
  };


  const ColumMenuWithToggle = React.forwardRef<
    HTMLUListElement,
    GridColumnMenuProps
  >(function GridColumnMenu(props: GridColumnMenuProps, ref) {
    const { hideMenu, currentColumn } = props;
    const expanded = getNestedValue(settings, ["TableExpanded",tableName], false);
    const toggleExpandText = expanded ? "Collapse All" : "Expand All"
    return (
      <GridColumnMenuContainer ref={ref} {...props}>
        <SortGridMenuItems onClick={hideMenu} column={currentColumn!} />
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn!} />
        <HideGridColMenuItem onClick={hideMenu} column={currentColumn!} />
        <GridColumnsMenuItem onClick={hideMenu} column={currentColumn!} />
        <Button
          onClick={(e) => {
            hideMenu(e);
            toggleExpandRows();
          }}
          variant={"text"}
          color={"primary"}
          sx={{
            mt: { xs: 1, sm: 0 }, color: "black"
          }}
        >
          {toggleExpandText}
        </Button>
      </GridColumnMenuContainer>
    );
  });

  const tableSettings: tableSettingsProps = {
    ...initialTableSettings,
    pinnedColumns: pinnedColumns,
    setPinnedColumns: setPinnedColumns,
    customColumnMenu: hasToggleMenu ? ColumMenuWithToggle : null,
    defaultGroupingExpansionDepth: defaultGroupingExpansionDepth,
    defaultExpanded: defaultExpanded,
    setdefaultExpanded: setdefaultExpanded,
    rowSelectionModel: rowSelectionModel,
    setRowSelectionModel: updateRowSelectionModel,
    columnVisibilityModel: columnVisibilityModel,
    setColumnVisibilityModel: updateColumnVisibilityModel,
    initalFilter: initalFilter,
    tableName:tableName
  }

  return tableSettings;
};

export const useTableSettings = (columns, rows?, tableName?, hasToggleMenu = false) => {
  let tableSettings = useInitialTableSettings(tableName, rows);
  tableSettings = { ...useCompleteTableSettings(columns, tableSettings, tableName) };

  const completeTableSettings: tableSettingsProps = {
    ...tableSettings,
  }

  return completeTableSettings;
};

export const tableSettingsToHeaderOnly = (tableSettings: tableSettingsProps) => {
  const modifiedTableSettings = tableSettings;
  modifiedTableSettings.autoHeight = false;
  modifiedTableSettings.hideFooter = true;
  modifiedTableSettings.loading = false;
  modifiedTableSettings.noRowsMessage = () => <></>
  return modifiedTableSettings;
}

