import { AxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { callApi } from "src/utils/services/callApi";
import { useApiStates } from "./useApiStates";

const useGet = <R = any,>(request: AxiosRequestConfig, dependency?: any, forceMock?: boolean): [R, () => void] => {
  const [results, setResults, setMessageContext, isMountedRef] = useApiStates<R>();
  let abort;
  useEffect(() => {
    abort = callApi(request, isMountedRef, setResults, setMessageContext)
  }, [dependency]);
  return [results, abort];
};
export default useGet;