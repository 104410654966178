import { SelectItem } from 'src/interfaces/form/select';
import { useManufacturers } from '../services/bold/manufacturers';
import { useGetOrderReferenceData, useGetProductReferenceData, useGetReturnOrderReferenceData, useGetSupplierReferenceData } from '../services/bold/products';
import { KeyValuePair } from 'src/interfaces/bold/product/product';
import formHelper from 'src/utils/form/formHelper';
import { useLowOrderPointsReferenceData, useSuppliers } from '../services/bold/purchasing';
import { Supplier } from 'src/interfaces/bold/product/supplier';
import { useGetCategories } from '../services/bold/salesitems';
import { mapCategoriesToTree } from '../table/useCategoriesTable';
import { useTransferOrderReferenceData } from '../services/bold/warehouse';


const mapSubClassOptions = (productClasses, productSubClasses) => {
  let productSubClassOptions = {}
  productClasses?.forEach(productClass => {
    productSubClassOptions[productClass?.Key] = [{ value: null, label: "-" }, ...formHelper.mapToOption<any>(productSubClasses?.filter(subClass => subClass.ParentId == productClass?.Key) ?? [], null)];
  });
  return productSubClassOptions;
};


const useManufacturerOptions = () => {
  const [items] = useManufacturers();
  const options = formHelper.mapToOption<KeyValuePair>(items, null);
  return options;
};





export const useCategoriesOptions = () => {
  const items = mapCategoriesToTree(useGetCategories());
  const options = formHelper.mapToOption<any>(items, "CategoryId", "path");
  return options;
};


const useProductOptions = (): [productTypeOptions: any[], productClassOptions: any[], productSubClassOptions: {}, manufacturerOptions: SelectItem[], productLinesOptions: any[], manufacturerOptions: SelectItem[]] => {
  const [referenceData] = useGetProductReferenceData();
  console.log(referenceData)
  return [
    formHelper.mapToOption<any>(referenceData?.ProductTypes ?? []),
    formHelper.mapToOption<any>(referenceData?.ProductClasses ?? []),
    mapSubClassOptions(referenceData?.ProductClasses, referenceData?.ProductSubClasses),
    formHelper.mapToOption<any>(referenceData?.Manufacturers ?? []),
    formHelper.mapToOption<any>(referenceData?.ProductLines ?? []),
    formHelper.mapToOption<any>(referenceData?.CountriesOfOrigin ?? []),
  ];
};
export const useSalesItemOptions = (): [productTypeOptions: any[]] => {
  const [referenceData] = useGetProductReferenceData();
  return [formHelper.mapToOption<any>(referenceData?.ProductTypes ?? [])];
};



export const useSupplierProductOptions = (): [supplierOptions: SelectItem[], manufacturerOptions: SelectItem[]] => {
  const items = useSuppliers();
  const supplierOptions = formHelper.mapToOption<Supplier>(items, "Supplier");
  const manufacturerOptions = useManufacturerOptions();
  return [supplierOptions, manufacturerOptions];
};

export const useSupplierOptions = () => {
  const [referenceData] = useGetSupplierReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.Currencies ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingMethods ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingTerms ?? []),
    formHelper.mapToOption<any>(referenceData?.PaymentMethods ?? []),
    formHelper.mapToOption<any>(referenceData?.PaymentTerms ?? []),
    formHelper.mapToOption<any>(referenceData?.OrderSendTypes ?? []),
  ];
};


export const useOrderOptions = (): [shippingMethodOptions: any[], shippingTermsOptions: any[], shippingRecieverOptions: any[]] => {
  const [referenceData] = useGetOrderReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.ShippingMethods ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingTerms ?? []),
    formHelper.mapToOption<any>(referenceData?.ShippingReceivers ?? []),
  ];
};

export const useReturnOrderOptions = (): [ReturnUnits: any[], ReturnReasons: any[]] => {
  const [referenceData] = useGetReturnOrderReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.ReturnUnits ?? []),
    formHelper.mapToOption<any>(referenceData?.ReturnReasons ?? []),
  ];
};
export const useLowOrderPointsOptions = (): [WarehouseList: any[], DocumentTypes: any[], DeliveryMethods: any[]] => {
  const [referenceData] = useLowOrderPointsReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.WarehouseList ?? []),
    formHelper.mapToOption<any>(referenceData?.DocumentTypes ?? []),
    formHelper.mapToOption<any>(referenceData?.DeliveryMethods ?? []),
  ];
};


export const useTransferOrderOptions = (): [DeliveryMethods: any[]] => {
  const [referenceData] = useTransferOrderReferenceData();
  return [
    formHelper.mapToOption<any>(referenceData?.DeliveryMethods ?? []),
  ];
};



export default useProductOptions;