import {  GridColDef, GridColumns } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from './useTableSettings';
import tableHelper from 'src/utils/tables/tableHelper';
import { useGetAllPurchaseOrderDrafts } from '../services/bold/purchasing';
import { ID_FIELD_PURCHASE_ORDERS } from 'src/utils/services/bold/idFields';
import useUpdateEffect from '../useUpdateEffect';




export const usePurchaseOrderDraftsTable = (): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetAllPurchaseOrderDrafts();
  console.log(rows)
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/purchasing/purchase-order-draft/edit?id=", "PurchaseOrderDraftId", "Edit Order"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'PurchaseOrderDraftId',width:50, headerName: 'Id', },
      { field: 'Status_Value', headerName: 'Status',width:120, valueGetter:  tableHelper.getFieldKeyOrValue, },
      { field: 'OrderValue', headerName: 'Order Value', valueGetter: tableHelper.getCurrency,type:"number",  width:130,  },
      { field: 'Currency_Value', headerName: 'Currency',width:120, valueGetter:  tableHelper.getFieldKeyOrValue, },
      { field: 'OrderValueInBaseCurrency', headerName: 'Value In Base Currency', valueGetter: tableHelper.getCurrency, type:"number",  width:130, },
      { field: 'OrderValueIncMiscCharges', headerName: 'Value Inc. Misc. Charges', valueGetter: tableHelper.getCurrency,type:"number",  width:130,  },
      { field: 'NoAutoAssign', headerName: 'NoAutoAssign', type:"boolean"},
      { field: 'CurrencyExchangeRate',width:80, headerName: 'Currency Exchange Rate',type:"number"  },
      { field: 'Supplier_Value', headerName: 'Supplier', type: 'dateTime', valueGetter:  tableHelper.getFieldKeyOrValue, width:180 },
      { field: 'CreateDate', headerName: 'Create Date', type: 'dateTime', valueGetter:  tableHelper.getDateColumn, },
      { field: 'CreatedBy', headerName: 'Created By' },
    ],
    [],
  );
  const tableName = "PurchaseOrderDraftsTable";
  const tableSettings = useTableSettings(columns, rows,tableName);
  tableSettings.idRow = ["PurchaseOrderDraftId"];
  return [(rows ?? []), columns, tableSettings];
};




export const usePurchaseOrderDraftRowsTable = (initialValues): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const [rows, setRows] = useState([])
  useUpdateEffect(() => {
    if (rows?.length < 1) {
      setRows(initialValues?.PurchaseOrderDraftRows ?? [])
    }
  }, [initialValues?.PurchaseOrderDraftRows])
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      { field: 'Product_Key', headerName: 'ArticleNumber', type:"number", valueGetter:  tableHelper.getFieldKeyOrValue,},
      { field: 'Product_Value', headerName: 'ProductName', valueGetter:  tableHelper.getFieldKeyOrValue,},
      { field: 'Quantity', headerName: 'Quantity', type:"number"},
      { field: 'SumRequestedQty', headerName: 'SumRequestedQty', type:"number"},
      { field: 'ListPrice', headerName: 'ListPrice', type:"number"},
      { field: 'NegotiatedPrice', headerName: 'NegotiatedPrice', type:"number"},
      { field: 'ManufacturerProductNo', headerName: 'ManufacturerProductNo'},
      { field: 'SupplierProductName', headerName: 'SupplierProductName'},
      { field: 'SupplierProductNo', headerName: 'SupplierProductNo'},
      { field: 'VendorAssortmentItemId', headerName: 'VendorAssortmentItemId'},
      { field: 'PurchaseOrderDraftRowId', headerName: 'PurchaseOrderDraftRowId'},
    ],
    [initialValues],
  );
  const tableSettings = useTableSettings(columns, rows, "PurchaseOrderDraftRow");
  tableSettings.idRow = ["PurchaseOrderDraftRowId"];
  
  tableSettings.autoHeight = true;
  tableSettings.hasToolbar=false;
  
  return [(tableSettings?.newRows ?? []), columns, tableSettings];
};
