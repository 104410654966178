import { Alert, AlertProps, } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomButton from './buttons/CustomButton';
import CloseIcon from '@mui/icons-material/Close';

export interface CustomAlertProps extends AlertProps {
    dataArray?: any[],
    hidden?: boolean,
    message?: string,
    setMessageContext?: Function,
}

export const CustomAlert = ({
    hidden = false,
    severity = 'success',
    dataArray,
    message,
    setMessageContext,
    ...props
}: CustomAlertProps) => {
    const [alertHidden, setAlertHidden] = useState<boolean>(hidden);
    const closeMessage = () => {
        dataArray = undefined;
        message = '';
        if (setMessageContext) setMessageContext('')
        setAlertHidden(true)
    }
    useEffect(() => {
        if (((message == undefined || message == '') && (dataArray == undefined))) {
            closeMessage();
        } else {
            setAlertHidden(false)
        }
    }, [dataArray, message]);
    if (!alertHidden && (!(message == undefined || message == '' || message == 'No Content') || !(dataArray == undefined))) {
        const dataAsString = JSON.stringify(dataArray, undefined, 2);
        let prettyData = null;
        if (dataAsString) {
            prettyData = dataAsString.replace(/\n/g, "<br>").replace(/[ ]/g, "&nbsp;").replace(/\,/g, ",").replace(/\\"/g, '"');
        }
        return (<> <Alert sx={{ maxWidth: "calc(100vw - 60px)", maxHeight: "200px", overflow: "auto" }} {...props}
            action={
                <CustomButton iconButton={true} color={severity} icon={<CloseIcon></CloseIcon>} onClick={closeMessage}></CustomButton>
            }
            style={{ paddingBottom: 2 }} severity={severity}>
            {(message ?? false) ? <>Message: {message}  </> : null}
            {(prettyData ?? false) ? <pre dangerouslySetInnerHTML={{ __html: prettyData }} ></pre> : null}
        </Alert></>);
    }
    return <></>

}
