import * as Yup from 'yup';
import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardTab, CardType, ContentCard, HeaderTabs } from 'src/components/organisms/ContentCard';
import { Formik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { FormContainerMolecule } from 'src/components/molecules/formik/FormContainerMolecule';
import { FormField } from 'src/components/molecules/formik/FormField';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import useSubmit from 'src/hooks/forms/useSubmit';
import { supplierPostRequest, supplierPutRequest } from 'src/utils/form/submitSupplierProduct';
import FormHelper from 'src/utils/form/formHelper';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { useSalesOrderTototalsTable as useSalesOrderTotalsTable } from 'src/hooks/table/useSalesOrdersTable';
import { useState } from 'react';
import { usePurchaseOrderRowsTable } from 'src/hooks/table/usePurchaseOrdersTable';
import { usePurchaseOrderDraftRowsTable } from 'src/hooks/table/usePurchaseOrderDraftsTable';
import { usePurchaseOrderDraftForm } from 'src/hooks/forms/useForms';

const scheme = Yup.object().shape({
});

const defaultInitialValues = {
};

export const EditBackorder = () => {
    const [editId, PageName, currentSubmitFunction, , isEditPage] = FormHelper.getEditPageConfig("Pruchase Order Drafts", supplierPostRequest, supplierPutRequest);
    const initialValues = usePurchaseOrderDraftForm(editId, defaultInitialValues)
    const [orderRowsRows, orderRowsColumns, orderRowsTableSettings] = usePurchaseOrderDraftRowsTable(initialValues)
    const [result, submit] = useSubmit(currentSubmitFunction);
    const tabs: CardTab[] = [
        { value: 'Order Rows', label: 'Purchase Order Rows' },
        { value: 'Properties', label: 'Properties' },
        { value: 'Resources', label: 'Resources' },
    ]
    const [currentTab, setCurrentTab] = useState<string>('Order Rows');
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={scheme}
                onSubmit={submit}
                enableReinitialize
            >
                {(props): JSX.Element => (
                    <div>
                        <PageHeader  {...{
                            title: PageName,
                            subTitle: "Create and Edit Purchase Order Draft",
                            components: [
                                { component: <CustomButton isSubmitting={props.isSubmitting} onClick={props.handleSubmit} title="Save" icon={<SaveIcon />} /> },
                                { isInGroupedMenu: true, component: <CustomButton variant="text" title="New Sales Order" icon={<AddTwoToneIcon />} actionUrl="/orders/orders/create" /> },
                            ],
                            alertData: { dataArray: (result ?? false) ? [result] : null }
                        }} />
                        <ContentCardsContainer >
                            <ContentCard {...{ cardId: "Order Info", md: 6 }}>
                                <FormContainerMolecule formikProps={props}>
                                    <FormField fieldProps={{ name: "PurchaseOrderDraftId", disabled: true, label: "Purchase Order Id", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "PurchaseOrderDraftType_Value", label: "PurchaseOrderDraftType", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "Status", label: "Status", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "OrderCurrency_Value", label: "OrderCurrency", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "OrderValueInBaseCurrency", label: "OrderValueInBaseCurrency", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "OrderValueIncMiscCharges", label: "OrderValueIncMiscCharges", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "Supplier_Value", label: "Supplier", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "SupplierContact", label: "Supplier Contact", md: 5.8 }} formikProps={props}></FormField>
                                </FormContainerMolecule>
                            </ContentCard>

                            <ContentCard {...{ cardId: "Dates", md: 6 }}>
                                <FormContainerMolecule formikProps={props}>
                                    <FormField fieldProps={{ name: "ModifyDate", label: "Modify Date", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "ModifiedBy", label: "Modified By", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "CreateDate", label: "CreateDate", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "CreatedBy", label: "Created By", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "SendDate", label: "Send Date", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "SendDestination", label: "SendDestination", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "OurContact", label: "Our Contact", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "DefaultCurrency.Currency.Value", label: "DefaultCurrency", md: 5.8 }} formikProps={props}></FormField>

                                </FormContainerMolecule>
                            </ContentCard>

                            <ContentCard {...{
                                cardId: "TabContainer",
                                contentPadding: 0,
                                cardTypes: [CardType.tabContainer],
                                cardHeader: <HeaderTabs {...{ tabs, currentTab, setCurrentTab }} ></HeaderTabs>,
                                components: [
                                    //{ isInGroupedMenu: true, component: <ActionButton target='blank' variant='text' title="New Supplier Product" icon={<AddTwoToneIcon />} actionUrl={"/products/supplier-products/create?productId=" + editId} />},
                                ],
                            }}>
                                {currentTab === "Order Rows" &&
                                    <ContentCard {...{ cardId: "Order Rows", cardTypes: [CardType.tab], contentPadding: 0, }}>
                                        <CustomDataGrid rows={orderRowsRows} columns={orderRowsColumns} tableSettings={orderRowsTableSettings} /> 
                                    </ContentCard>
                                }
                                {currentTab === "Properties" &&
                                    <ContentCard {...{ cardId: "Properties", cardTypes: [CardType.tab], md: 12 }}>

                                    </ContentCard>
                                }
                                {currentTab === "Resources" &&
                                    <ContentCard {...{ cardId: "Resources", cardTypes: [CardType.tab], md: 12 }}>

                                    </ContentCard>
                                }


                            </ContentCard>


                        </ContentCardsContainer>
                    </div>
                )}
            </Formik>


            <Footer />
        </>
    );
}

export default EditBackorder;
