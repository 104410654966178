import { Link } from 'react-router-dom';
import { Grid, Typography, Breadcrumbs, Box, IconButton, Tooltip } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/molecules/pageHeader/PageTitleWrapper';
import { useTranslation } from 'react-i18next';
import AdditionalActions from '../atoms/buttons/AdditionalActions';
import TooltipField from '../atoms/TooltipField';
import { customComponent } from 'src/interfaces/customComponent';
import { separateMenuComponents } from 'src/utils/buttons/separateMenuComponents';
import useBreadcrumbs from 'src/hooks/pageHeader/useBreadcrumbs';
import { CustomAlert, CustomAlertProps } from '../atoms/CustomAlert';
import { useContext } from 'react';
import { MessageContext } from 'src/contexts/MessageContext';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useSettingsContext } from 'src/hooks/useUserPrefs';
import FloatingChat from './chat/Chat';

export interface PageHeaderProps {
  title: string| React.ReactNode;
  subTitle?: string  | React.ReactNode;
  tooltipText?: string;
  components?: customComponent[];
  alertData?: CustomAlertProps;
}

const PageHeader = (pageHeaderData: PageHeaderProps) => {
  const { t }: { t: any } = useTranslation();
  const breadcrumbs = useBreadcrumbs();
  const [TopRightComponents, MenuItemsComponents] = separateMenuComponents(pageHeaderData.components);
  const { message, messageType, setMessageContext } = useContext(MessageContext)
  const [settings] = useSettingsContext()

  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <>
      <Helmet>
        <title>{pageHeaderData.title}</title>
      </Helmet>
      <PageTitleWrapper >
        <Grid container columnSpacing={2} justifyContent="space-between" alignItems="center">
          <Grid item sm={12} md={true} sx={ { width: { xs:"101%",md:"inital"}} }>
            <Box
              component="span"
              sx={{
                display: { md: 'none', xs: 'inline-block' },
                float: "right"
              }}
            >
              <Tooltip arrow title="Toggle Menu">
                <IconButton color="primary" onClick={toggleSidebar}>
                  {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h3" component="h3" gutterBottom>
              {t(pageHeaderData.title)}
              <TooltipField tooltipText={pageHeaderData.tooltipText}></TooltipField>
            </Typography>
            <Typography variant="subtitle2">
              {pageHeaderData.subTitle}
            </Typography>

            <Box>
              <Breadcrumbs maxItems={5} aria-label="breadcrumb">
                {breadcrumbs.path.map((path, i) => {
                  return (
                    <Link key={"link" + i} color="inherit" to={path} >
                      {t(breadcrumbs.label[i])}
                    </Link>
                  )
                }
                )}
              </Breadcrumbs>
            </Box>

          </Grid>
          <Box display="flex" justifyContent="right"
            sx={{
              width: { xs: "100%", md: "initial" },
            }} >
            {TopRightComponents?.map((customComponent, i) => (
              <Grid
                pl={1}
                key={i} item
                width="100%"
                sx={{
                  width: { xs: "100%", sm: "initial" },
                  justifyContent: { xs: "center", sm: "right" }
                }} >
                {customComponent.component}
              </Grid>
            ))}
            <AdditionalActions >
              {MenuItemsComponents?.length > 0 ? MenuItemsComponents?.map((customComponent, i) => (
                <Grid key={i} item xs={12}>
                  {customComponent.component}
                </Grid>
              )) : null}
            </AdditionalActions>
          </Box>
          <Grid item md={12} marginTop={2}>
            {(settings?.AlertMessages ?? true )? <CustomAlert severity={messageType} setMessageContext={setMessageContext} {...pageHeaderData.alertData} message={t(message)} ></CustomAlert> : null}
          </Grid>
        </Grid>


      </PageTitleWrapper>
      {settings?.ShowChat ? <FloatingChat />: null}
    </>
  );
}

export default PageHeader;