import { AxiosRequestConfig } from 'axios';
import useRequest from '../services/useRequest';
import useRefMounted from '../useRefMounted';

const useSubmit = <D = any,R = any>(
    getCurrentRequest: (v:D) => AxiosRequestConfig<any> | AxiosRequestConfig<any>[]
  ): [
    results: R,
    submit: (
      values: D,
      {
        setErrors,
        setStatus,
        setSubmitting,
      }: {
        setErrors: any;
        setStatus: any;
        setSubmitting: any;
      }
    ) => void
  ] => {
    const isMountedRef = useRefMounted();
    const [results, fetch] = useRequest<D, R>();
    async function submit(
      values: D,
      { setErrors, setStatus, setSubmitting }: { setErrors: any; setStatus: any; setSubmitting: any }
    ): Promise<void> {
      try {
        const requests = getCurrentRequest(values);
  
        // Check if it's an array of requests
        if (Array.isArray(requests)) {
          requests.forEach((request) => {
            fetch(request);
            console.log(request);
          });
        } else {
          fetch(requests);
          console.log(requests);
        }
  
        if (isMountedRef()) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      } catch (err) {
        console.error(err);
        if (isMountedRef()) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }
    }
    return [results, submit];
  };
  
  export default useSubmit;
  
