import { AlertColor } from '@mui/material';
import { useState, createContext, FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
interface MessageContextProps {
  message: string;
  messageType: AlertColor;
  setMessageContext: Function;
}

export const MessageContext = createContext<MessageContextProps>(
  {
    message: "",
    messageType: "success",
    setMessageContext: () => { }
  }
);

export const MessageProvider: FC = ({ children }) => {
  const location = useLocation()
  const [message, setMessage] = useState();
  useEffect(() => {
    setMessage(null)
  }, [location]);
  const [messageType, setMessageType] = useState();
  const setMessageContext = (newMessage, newMessageType) => {
    if ((newMessage != undefined) && (newMessage != "")) {
      // console.log("MESSAGE: " + newMessage + " Type: " + newMessageType);
      // console.log(newMessage)
    }
    const ExceptionMessage = newMessage?.ExceptionMessage
    const internalMessage = newMessage?.Message
    setMessage(ExceptionMessage ?? (internalMessage ?? newMessage));
    setMessageType(newMessageType)
  }
  return (
    <MessageContext.Provider
      value={{
        message,
        messageType,
        setMessageContext,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
