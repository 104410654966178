import { ProductTypeAttributes } from "src/interfaces/bold/attributes/attribute";
import { IdName } from "src/interfaces/bold/idName";
import { CountryRespone as CountryResponse, IdNameDictionary, ProductClassResponse, ProductCreateRequest, ProductLineRepsone, ProductResponse, ProductsResponse, ProductTypeResponse } from "src/interfaces/bold/product/product";
import { createProduct, getAllProducts, getAttribues, getAttribuesByProductType, getCountries, getDataTypes, getOrderReferenceData, getProductById, getProductClasses, getProductLines, getProductReferenceData, getProductStatuses, getProductTypes, getReturnOrderReferenceData, getSupplierReferenceData, getUnits, MAX_PAGESIZE, searchProducts, updateProduct } from "src/utils/services/bold/requests";
import useGetAllPaginatedItems from "../useGetAllPaginatedItems";
import { useResponse } from "../useResponse";

// GET
export const useGetProductClasses = () => {
    return useResponse<ProductClassResponse[]>(getProductClasses);
}
export const useGetProductSubClasses = (productClassId: number) => {
    return useResponse<IdNameDictionary[]>(getProductTypes,{productClassId});
}
export const useGetProductTypes = () => {
    return useResponse<ProductTypeResponse[]>(getProductTypes);
}
export const useGetProductLines = () => {
    return useResponse<ProductLineRepsone[]>(getProductLines);
}
export const useGetProductStatuses = () => {
    return useResponse<IdName[]>(getProductStatuses);
}
export const useGetProductById = (id: string) => {
    const [item] = useResponse<ProductResponse>(getProductById,{id}, true);
    return item
    
}
export const useGetAllProducts = () => {
    return useGetAllPaginatedItems<ProductsResponse>(getAllProducts, MAX_PAGESIZE);
}
export const useSearchProducts = (searchKey: string) => {
    return useResponse<ProductsResponse[]>(searchProducts,{searchKey});
}

export const useGetCountries = () => {
    return useResponse<CountryResponse[]>(getCountries);
}
export const useGetAttributes = () => {
    return useResponse<any[]>(getAttribues);
}
export const useGetAttributesByProductType = (productTypeId:string) => {
    const [attributes] = useResponse<ProductTypeAttributes>(getAttribuesByProductType, {productTypeId}, true, productTypeId);
    return attributes?.Properties
}
export const useGetUnits = () => {
    return useResponse<any[]>(getUnits);
}
export const useGetDataTypes = () => {
    return useResponse<any[]>(getDataTypes);
}
export const useGetProductReferenceData = () => {
    return useResponse<any>(getProductReferenceData);
}
export const useGetOrderReferenceData = () => {
    return useResponse<any>(getOrderReferenceData);
}
export const useGetSupplierReferenceData = () => {
    return useResponse<any>(getSupplierReferenceData);
}
export const useGetReturnOrderReferenceData = () => {
    return useResponse<any>(getReturnOrderReferenceData);
}

// POST
export const useCreateProduct = (productCreateRequest: ProductCreateRequest) => {
    return useResponse<ProductResponse>(createProduct,{productCreateRequest});
}
// PUT
export const useUpdateProduct = (id: number, productUpdateRequest: ProductCreateRequest) => {
    return useResponse<ProductResponse>(updateProduct,{id, productUpdateRequest});
}