import { MAX_PAGESIZE, getAllProducts, getAllPurchaseOrderDrafts, getAllPurchaseOrders, getAllReturnOrders, getAllSalesOrders, getAllSupplierProducts, getAllTransferOrders, getSuppliers, getWarehouses } from "src/utils/services/bold/requests";
import { useOrdersById, useReturnsById } from "../services/bold/orders";
import useFormData from "./useFormData";
import { useMemo } from "react";
import formHelper from "src/utils/form/formHelper";
import { useSuppliersById, useGetSupplierProductById, usePurchaseOrderById, usePurchaseOrderDraftById } from "../services/bold/purchasing";
import { useTableCacheForForm } from "./useTableCacheForForm";
import { useGetTransferOrderById, useGetWarehouseById } from "../services/bold/warehouse";
import { useGetProductById } from "../services/bold/products";

export const useProductForm = (editId, defaultInitialValues) => {
  const initialValues = useFormData(editId, defaultInitialValues, useGetProductById, getAllProducts(MAX_PAGESIZE, 0).url, "ProductId");
  return initialValues;
}

export const useSalesOrderForm = (editId, defaultInitialValues) => {
  const initialValues = useFormData(editId, defaultInitialValues, useOrdersById, getAllSalesOrders(500, 0).url, "SalesOrderId");
  return initialValues;
}

export const useReturnOrderForm = (editId, defaultInitialValues) => {
  const initialValues = useFormData(editId, defaultInitialValues, useReturnsById, getAllReturnOrders(500, 0).url, "ReturnOrderId");
  return initialValues;
}

export const useSupplierForm = (editId: string, defaultInitialValues): any => {
  const initialValues = useFormData(editId, defaultInitialValues, useSuppliersById, getSuppliers().url, "Id");
  return initialValues;
};

export const useSupplierProductForm = (editId: string, defaultInitialValues): any => {
  const initialValues = useFormData(editId, defaultInitialValues, useGetSupplierProductById, getAllSupplierProducts(1000, 0).url, "SupplierAssortmentItemId");
  return initialValues;
};

export const usePurchaseOrderForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, defaultInitialValues, usePurchaseOrderById, getAllPurchaseOrders(500, 0).url, "PurchaseOrderId");
  return initialValues;
};

export const usePurchaseOrderDraftForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, defaultInitialValues, usePurchaseOrderDraftById, getAllPurchaseOrderDrafts(500, 0).url, "PurchaseOrderDraftId");
  return initialValues;
};

export const useWarehouseForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, defaultInitialValues, useGetWarehouseById, getWarehouses().url, "WarehouseId");
  return initialValues;
};

export const useTransferOrderForm = (editId: string, defaultInitialValues): { initialValues: any } => {
  const initialValues = useFormData(editId, defaultInitialValues, useGetTransferOrderById, getAllTransferOrders(500, 0).url, "OrderId");
  return initialValues;
};
