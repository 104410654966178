import { customComponent } from "src/interfaces/customComponent";

export function separateMenuComponents(components: customComponent[]) {
    let OtherComponents = [];
    let MenuItemsComponents = [];
    components?.forEach(function (customComponent) {
      if (customComponent.isInGroupedMenu) {
        MenuItemsComponents.push(customComponent);
      }else{
        OtherComponents.push(customComponent);
      }
    })
    return [OtherComponents, MenuItemsComponents]
  }