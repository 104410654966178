import { AxiosRequestConfig } from "axios";
import { useCallback, useRef, useState } from "react";
import { callApi } from "src/utils/services/callApi";
import useUpdateEffect from "../useUpdateEffect";
import { useApiStates } from "./useApiStates";

const useRequest = <D = any, R = any>(): [
    results: R,
    setRequest: (request: AxiosRequestConfig<D>, callback?: <T = any, >(responseData: T, other?: any) => T | any) => void,
    abort?: () => void
] => {
    const [results, setResults, setMessageContext, isMountedRef] = useApiStates<R>();
    const [request, setPostPutRequest] = useState<AxiosRequestConfig<D>>(null);
    const [successCallback, setSuccessCallback] = useState<(<T, >(responseData: T) => T | any) | undefined>(undefined)
    const abortController = useRef(null);

    const abort = useCallback(() => {
        return abortController?.current && abortController.current.abort('Cancelled by user input. useUpdateDashboardContext');
    }, [request]);

    const setRequest = useCallback((request: AxiosRequestConfig<D>, callback: <T, >(responseData: T) => T | any = undefined) => {
        // console.log("Setting request", request.url)
        setPostPutRequest(request)
        setSuccessCallback(() => callback);
    }, [request]);

    useUpdateEffect(() => {
        abortController.current = new AbortController();
        callApi(request, isMountedRef, setResults, setMessageContext, successCallback, abortController.current.signal);
    }, [request])
    return [results, setRequest, abort];
};

export default useRequest;