import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { usePurchaseOrderDraftsTable } from 'src/hooks/table/usePurchaseOrderDraftsTable';
import { useBackordersRowsTable, useBackordersTable } from 'src/hooks/table/useBackordersTable';
import { useLowOrderPointsRowsTable } from 'src/hooks/table/useLowOrderPointsTable';
import DetailPanelContainer from 'src/components/organisms/tables/DetailPanelContainer';

export const Backorders = () => {
    const [rows, columns, tableSettings] = useBackordersTable();
    return (
        <>
            <PageHeader  {...{
                title: "Backorders",
                subTitle: "Backorders",
                components: [
                    
                ]
            }} />
            <ContentCardsContainer >
                <ContentCard {...{ cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid rows={rows}columns={columns} tableSettings={tableSettings}  getDetailPanelContent={(row) => <DetailPanelContent {...{ row }} />} />
                </ContentCard>
            </ContentCardsContainer>
            <Footer />
        </>
    );
}
export default Backorders;




const DetailPanelContent = ({ row }) => {
    const [rows, columns, tableSettings] = useBackordersRowsTable(row?.row?.DetailRows);
    return (
        <DetailPanelContainer>
            <CustomDataGrid rows={rows}columns={columns} tableSettings={tableSettings} ></CustomDataGrid>
        </DetailPanelContainer>
    );
}
