import { MenuItem, Button, TextField, Grid, Paper, styled } from "@mui/material";
import { GridColumnsPanel, GridPanelContent, GridPanelFooter, GridPanelHeader } from "@mui/x-data-grid";
import { useState, useEffect, useMemo } from "react";
import { useSettingsContext } from "src/hooks/useUserPrefs";
import { getNestedValue } from "src/utils/settings";
import { CustomField } from "../formik/CustomField";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CustomButton from "src/components/atoms/buttons/CustomButton";
import AddIcon from '@mui/icons-material/Add';

export const CustomColumnsPanel = ({ tableSettings }) => {
    const [settings, setSettings] = useSettingsContext();
    const [selectOpen, setSelectOpen] = useState(false);
    const profiles = useMemo(() => {
        const rawProfiles = getNestedValue(settings, ["TableColumnVisibilityViewProfiles", tableSettings?.tableName], ["Default"]);
        return Array.from(new Set(rawProfiles));
    }, [settings]) as any[];
    const [view, setView] = useState(profiles[0]);
    const [editState, setEditState] = useState({});
    const [editableValue, setEditableValue] = useState({});

    const setColumnVisibilityView = (viewName) => {
        const viewSettings = getNestedValue(settings, ["TableColumnVisibilityView", tableSettings?.tableName + viewName], tableSettings.columnVisibilityModel)
        tableSettings.setColumnVisibilityModel({
            ...viewSettings,
        })
    }
    useEffect(() => {
        setColumnVisibilityView(view)
    }, [view])


    const saveView = () => {
        setSettings(tableSettings.columnVisibilityModel, "TableColumnVisibilityView", tableSettings?.tableName + view)

    }

    const addNewProfile = () => {
        const newProfileName = "New Profile";

        // Add the new profile to the settings
        const updatedProfiles = [...profiles, newProfileName];
        setSettings(updatedProfiles, "TableColumnVisibilityViewProfiles", tableSettings?.tableName);

        // Set the new profile to be in edit mode
        setEditState({ ...editState, [newProfileName]: true });
        setEditableValue({ ...editableValue, [newProfileName]: "New Profile" });

        // Open the Select component if it's not already open
        setSelectOpen(true);
    };

    const handleEditClick = (event, profile) => {
        event.stopPropagation();
        setEditState({ ...editState, [profile]: true });
        setEditableValue({ ...editableValue, [profile]: profile });
    };

    const handleTextFieldChange = (event, profile) => {
        event.stopPropagation();
        console.log(editableValue)
        setEditableValue({ ...editableValue, [profile]: event.target.value });
    };

    const handleSaveClick = (event, profile) => {
        event.stopPropagation();
        const updatedProfileName = editableValue[profile];

        // Ensure the new profile name is not empty or the same as the old one
        if (!updatedProfileName || updatedProfileName === profile) {
            setEditState({ ...editState, [profile]: false });
            return;
        }

        // Replace the old profile name with the new one in the profiles list
        const updatedProfiles = profiles.map(p => p === profile ? updatedProfileName : p);
        const currentVisibilitySettings = getNestedValue(settings, ["TableColumnVisibilityView", tableSettings?.tableName + profile], {});
        // Update the profiles in settings
        setSettings(updatedProfiles, "TableColumnVisibilityViewProfiles", tableSettings?.tableName);
        setSettings(currentVisibilitySettings, "TableColumnVisibilityView", tableSettings?.tableName + updatedProfileName);
        // Update the editable value and edit state
        const newEditState = { ...editState };
        delete newEditState[profile];
        setEditState(newEditState);

        const newEditableValue = { ...editableValue };
        delete newEditableValue[profile];
        setEditableValue(newEditableValue);
    };

    return (
        <div style={{ height: '800px', minWidth: "400px" }}>
            <Paper>
                
                <Grid container spacing={0} >
                    <Grid item xs={10}>
                        <CustomField options={{ name: "Select Table Profile", select: true, selectOpen: selectOpen, setSelectOpen: setSelectOpen }} stateProps={{ state: view, setState: setView }}>
                            {profiles.map((option) => (
                                editState[option] ? (
                                    <MenuItem key={option}>
                                        <TextField
                                            value={editableValue[option]}
                                            onChange={(e) => handleTextFieldChange(e, option)}
                                            onClick={(e) => e.stopPropagation()}
                                            fullWidth
                                        />
                                        {selectOpen ? <CustomButton size="small" iconButton={true} icon={<SaveIcon></SaveIcon>} onClick={(e) => handleSaveClick(e, option)} title="Save" /> : null}
                                    </MenuItem>
                                ) : (
                                    <MenuItem key={option} value={option}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}>
                                            {option}
                                            {selectOpen ? <CustomButton
                                                size="small"
                                                iconButton={true}
                                                icon={<EditIcon />}
                                                onClick={(e) => handleEditClick(e, option)}
                                                title="Edit"
                                            /> : null}
                                        </div>
                                    </MenuItem>

                                )
                            ))}
                        </CustomField>
                    </Grid>
                    <Grid item sx={{ padding: "20px 0px 0 0px" }} xs={1}>
                        <CustomButton size="medium" iconButton={true} icon={<SaveIcon></SaveIcon>} onClick={saveView} title="Save View" />
                    </Grid>
                    <Grid item sx={{ padding: "20px 0px 0 0px" }} xs={1}>
                        <CustomButton size="medium" iconButton={true} icon={<AddIcon></AddIcon>} onClick={addNewProfile} title="Add New Profile" />
                    </Grid>
                </Grid>
                <StyledGridColumnsPanel></StyledGridColumnsPanel>
            </Paper>

        </div >
    );
};


const StyledGridColumnsPanel = styled(GridColumnsPanel)(
    () => `.MuiDataGrid-panelHeader{
    display:none !important;}
  `
  );

