import { useRef, useEffect, useCallback } from 'react';

const useRefMounted = (): () => boolean => {
  const isMountedRef = useRef(false)
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  return isMounted;
};

export default useRefMounted;
