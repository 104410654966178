export const convertToBoolean = (obj)  => {
    for (let key in obj) {
        const value = String(obj[key]).toLowerCase();
        if (value == 'true') {
            obj[key] = true;
        } else if (value == 'false') {
            obj[key] = false;
        }
    }
    return obj;
}


export const getNestedValue = (obj, keys, defaultValue = null) => {
    let current = obj;
    for (let key of keys) {
      if (current && current.hasOwnProperty(key)) {
        current = current[key];
      } else {
        return defaultValue;
      }
    }
    return current;
  };
  
  export const getNestedValueByPartialKeys = (obj, keys, defaultValue = null) => {
    let current = obj;
    for (let searchKey of keys) {
        let found = false;
        if (current) {
            for (let key in current) {
                if (current.hasOwnProperty(key) && key.startsWith(searchKey)) {
                    current = current[key];
                    found = true;
                    break;
                }
            }
        }
        if (!found) {
            return defaultValue;
        }
    }
    return current;
};

export const checkAnyTrueNestedValue = (obj, keys) => {
  let current = obj;
  for (let searchKey of keys) {
      if (current) {
          let found = false;
          for (let key in current) {
              if (current.hasOwnProperty(key) && key.startsWith(searchKey)) {
                  if (typeof current[key] === 'object') {
                      // If the value is an object, recurse with the remaining keys
                      if (checkAnyTrueNestedValue(current[key], keys.slice(keys.indexOf(searchKey) + 1))) {
                          return true;
                      }
                  } else if (current[key] === true) {
                      // If the value is true, return true
                      return true;
                  }
                  found = true;
              }
          }
          if (!found) {
              // If no matching key is found in the current object, return false
              return false;
          }
      } else {
          // If current is not an object, return false
          return false;
      }
  }
  // If no true value is found, return false
  return false;
};
