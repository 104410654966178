import { TextField } from "@mui/material";
import { get } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface CustomFieldProps {
    stateProps?: {
        values?: any,
        handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
        touched?: any,
        errors?: any,
        handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
        state?: any,
        setState?: React.Dispatch<React.SetStateAction<any>>
    },
    options: {
        label?: string,
        disabled?: boolean,
        multiLine?: boolean,
        select?: boolean,
        isMulti?: boolean,
        type?: string,
        name: string,
        height?: string,
        selectOpen?: boolean,
        setSelectOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    },
    children?: ReactNode,
}

export const CustomField = ({
    options,
    stateProps,
    children,
}: CustomFieldProps) => {
    const { t } = useTranslation();
    const label = t(options.label ?? (options.name.charAt(0).toUpperCase() + options.name.slice(1)));
    const onChangeFunction = stateProps?.setState != undefined ? (event) => { stateProps?.setState(event.target.value) } : stateProps?.handleChange;
    let value = stateProps?.state ?? get(stateProps?.values ?? {}, options.name, "");
    if ((options.select && !options.isMulti) && (typeof value === 'object' || value === undefined)) {
        value = ''; // Resetting value to empty string for invalid value
    }
    const [selectOpen, setSelectOpen] = useState(false);
    useEffect(() => {
        if (options.selectOpen !== undefined) {
            setSelectOpen(options.selectOpen);
        }
    }, [options.selectOpen]);
    const handleSelectOpen = () => {
        setSelectOpen(true);
        options?.setSelectOpen(true);
    };
    const handleSelectClose = () => {
        setSelectOpen(false);
        options?.setSelectOpen(false);
    };

    const errorText = get(stateProps?.touched, options.name) && get(stateProps?.errors, options.name);

    return (
        <TextField
            label={label}
            name={options.name}
            value={value}

            onBlur={stateProps?.handleBlur}
            multiline={options.multiLine ?? false}
            onChange={onChangeFunction}
            inputProps={{ readOnly: options.disabled }}
            select={options.select ?? false}
            SelectProps={{
                multiple: options.isMulti ?? false, open: selectOpen,
                onOpen: handleSelectOpen,
                onClose: handleSelectClose,
            }}
            helperText={errorText}
            error={Boolean(errorText)}
            type={options.type ?? "text"}
            variant="outlined"
            fullWidth
            sx={{
                '& .MuiOutlinedInput-root': {
                    height: options?.height == null ? "auto" : options.height + "  !important",
                }
            }}
            margin="normal"
        >
            {children}
        </TextField>
    );
};