import { AxiosRequestConfig, Method } from 'axios';
import { useResponse } from './services/useResponse';
import useAuth from './useAuth';
import { getTenant } from 'src/utils/axios';
import { Dispatch, useContext, useState } from 'react';
import { IPatchOperatioType, PatchOperatioType } from 'src/interfaces/requests/patch';
import { SettingsContext } from 'src/contexts/SettingsContext';
import { EditsContext } from 'src/contexts/EditsContext';

export const useSettingsContext = ():[any,(value: any, key?: string, nestedKey?: string) => void] => {
  const settingsContext = useContext(SettingsContext);
  const [settings, setSettings] = [settingsContext.userSettings, settingsContext.setUserSettings]
  return [settings, setSettings]
}
export const useUserEditsContext = () => {
  const editsContext = useContext(EditsContext);
  const [userEdits, setUserEdits] = [editsContext.userEdits, editsContext.setUserEdits]
  return [userEdits, setUserEdits]
}

export const useUserSettings = (): [any, (value: any, key?: string, nestedKey?: string | null) => void] => {
  return useUserPreferenceWithProperty('/settings', 'settings');
};
export const useUserEdits = (): [any, (value: any, key?: string, nestedKey?: string | null) => void] => {
  return useUserPreferenceWithProperty('/edits', 'edits');
};


const useUserPreferenceWithProperty = (path: string, propertyName: string): [any, (value: string | boolean, key?: string, nestedKey?: string | null) => void] => {
  const [userPreferences, setUserPrefs, setCurrentUserPrefs] = useUserPrefs();
  let [propertyValue, nonDefaultKey] = getPropertyValue(userPreferences ?? {}, propertyName);
  propertyValue = parseJSONString(propertyValue);
  const setUserProperty = (value: any, key?: string, nestedKey?: string | null) => {
    if (!key) {
      setUserPrefs(path, value);
      updateUserPreferences(userPreferences, nonDefaultKey, propertyName, value, setCurrentUserPrefs);
      return;
    }
    const updatedPropertyValue = { ...propertyValue };
    if (nestedKey) {
      if (!updatedPropertyValue[key]) {
        updatedPropertyValue[key] = {};
      }
      updatedPropertyValue[key][nestedKey] = value;
    } else {
      updatedPropertyValue[key] = value;
    }
    updateUserPreferences(userPreferences, nonDefaultKey, propertyName, updatedPropertyValue, setCurrentUserPrefs);
    setUserPrefs(path, updatedPropertyValue);
  };
  return [propertyValue, setUserProperty];
};






const useUserPrefs = (): [any, (path: string, value: string | boolean) => void, Dispatch<any>] => {
  const { user } = useAuth();
  const email = user?.email;
  const [updateData, setUpdateDataState] = useState<any | null>(null);
  const [userPreferences, setCurrentUserPrefs] = useResponse(getUserPreferences, { email });
  useResponse(setUserPreferences, { email, data: updateData }, false, updateData);
  const setUserPrefs = (path: string, value: string) => {

    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    const patchObject = getReplacePatchObject(path, value);
    setUpdateDataState(patchObject);
  };
  return [userPreferences, setUserPrefs, setCurrentUserPrefs];
};







export const getReplacePatchObject = (path: string, value: string | boolean, entity?: IPatchOperatioType[]): IPatchOperatioType[] => {
  entity = entity || [];
  entity.push({
    op: PatchOperatioType.Replace,
    path: path,
    value: value
  });
  return entity;
};


const parseJSONString = (propertyValue) => {
  if (typeof propertyValue === 'string' && propertyValue.startsWith('{') && propertyValue.endsWith('}')) {
    try {
      return JSON.parse(propertyValue);
    } catch (e) {
      // If parsing fails, keep the original string value
      return propertyValue;
    }
  }
  return propertyValue;
};

const getPropertyValue = (data, propertyName) => {
  const keys = Object.keys(data || {});
  const nonDefaultKey = keys.find(key => key !== 'default');
  const nonDefaultValue = data?.[nonDefaultKey]?.[propertyName];
  return [nonDefaultValue, nonDefaultKey] ?? [(data?.default?.[propertyName] || null), null];
};
const updateUserPreferences = (userPreferences, nonDefaultKey, propertyName, updatedPropertyValue, setCurrentUserPrefs) => {
  const newPrefs = { ...userPreferences };
  if (!newPrefs[nonDefaultKey]) {
    newPrefs[nonDefaultKey] = {};
  }
  newPrefs[nonDefaultKey][propertyName] = updatedPropertyValue;
  setCurrentUserPrefs(newPrefs);
}


//ADJUST THE EXISTING ONE TO WORK WITH TENANT AS WELL AND IMPORT LATER
const buildRequestConfig = (
  UrlParts: (string | number | boolean)[],
  requestMethod: Method = "GET",
  data: any = null,
  user: any = null
): AxiosRequestConfig<any> => {
  const mergedUrl = UrlParts.join('')
  const request = {
    url: mergedUrl,
    method: requestMethod,
    headers: {
      Email: user?.email,
      Tenant: `${getTenant()}_${process.env.REACT_APP_ENV}`,
    },
    data: data
  }
  //console.log(request)
  return request
}

export const getUserPreferences = (email: string): AxiosRequestConfig => (
  buildRequestConfig(["/api/GetUserPreferences"], "GET", null, email)
);

export const setUserPreferences = (email: string, data: any): AxiosRequestConfig => (
  buildRequestConfig(["/api/SetUserPreferences"], "PATCH", data, email)
);
