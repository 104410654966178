import { useMemo } from "react";
import formHelper from "src/utils/form/formHelper";
import { useTableCacheForForm } from "./useTableCacheForForm";

export const useFormData = (editId,defaultInitialValues,getItemsFunction,cacheKey,itemKey) =>{
    const item = getItemsFunction(editId);
    console.log(item)
    const matchedItem = useTableCacheForForm(cacheKey,itemKey,editId)
    const initialValues = useMemo(() => {
      let newInitalValue:any = formHelper.mapToInitialValues(item??matchedItem) ?? {}
      newInitalValue.isEditPage = editId != null ? editId : "";
      return editId != null ? newInitalValue : defaultInitialValues;
    }, [item,  editId, defaultInitialValues,matchedItem]);
    console.log(initialValues)
    return initialValues;
  
  }

  export default useFormData;