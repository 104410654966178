import { useEffect, useState } from 'react';
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import { useAi } from 'src/hooks/useAi';

const AiButton = ({ formikProps, fieldName, fieldInstructions = 'Generate a description of maximum 60 words for the product 13" Top Hat ikat Chevron. The text is for a webshop for a high end interior design brand. Some info about the product. Its a Lampshade, from the brand Frank & Cordinata. Height 32 cm. Color beige' }) => {
    
  const [textFieldValue, setTextFieldValue] = useState(fieldInstructions);
  const [responses, generateResponse] = useAi()

  const handleClick = () => {
    generateResponse(textFieldValue);
  };
  useEffect(()=>{
    formikProps.setFieldValue(fieldName, responses.join(""));
  },[responses])

  console.log(responses)

  return (
    <>
      <Tooltip arrow placement="top-end" title={"Generate text with AI"}>
        <IconButton
          size="small"
          sx={{ ml: 1 }}
          color="primary"
          onClick={handleClick}
        >
          <SmartToyOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      
    </>
  );
};

export default AiButton;
