import { GridColDef, GridColumns, GridRenderCellParams, GridRowId, GridRowParams } from '@mui/x-data-grid';
import { tableSettingsProps, useTableSettings } from './useTableSettings';
import tableHelper from 'src/utils/tables/tableHelper';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { useGetCategories } from '../services/bold/salesitems';
import { CategoryResponse } from 'src/interfaces/bold/salesitem/salesitem';
import React, { useMemo, useState } from 'react';
import { ID_FIELD_CATEGORIES, ID_FIELD_CATEGORIES_ON_SALES_ITEMS } from 'src/utils/services/bold/idFields';
import useUpdateEffect from '../useUpdateEffect';



const getNameAndParentId = (id, categories) => {
    const parentName = categories?.find(ct => ct.CategoryId == id)?.Name.toString() ?? "Uncategoriezed";
    const parentId = categories?.find(ct => ct.CategoryIdId == id)?.ParentId ?? -1;
    return [parentName, parentId]
}

export const mapCategoriesToTree = (categories: CategoryResponse[]) => {
    let treeRows = [];
    if (categories != null) {
        categories?.forEach(category => {
            const originalParentName = categories?.find(ct => ct.CategoryId == category?.ParentId)?.Name.toString();
            const parentPath = treeRows.find(ct => ct.CategoryId == category?.ParentId)?.path ?? [""];
            let newPath = [...parentPath, (category?.Name + category.CategoryId?.toString())] ?? originalParentName ?? [];
            let backwardsPath = [];
            if (category.CategoryId < category.ParentId) {
                let currentParentId = category.ParentId;
                while (currentParentId != null && currentParentId > 0) {
                    const [parentName, parentId] = getNameAndParentId(currentParentId, categories);
                    backwardsPath = [...backwardsPath, parentName];
                    currentParentId = parentId;
                }
                backwardsPath.reverse();
                newPath = [...backwardsPath, category?.Name];
            }
            treeRows.push({
                path: newPath ?? " ",
                Name: category?.Name,
                CategoryId: category?.CategoryId,
            })
        });
    }
    return treeRows;
}

export const useCategoriesTable = (): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
    const categories: CategoryResponse[] = useGetCategories();

    const rows = useMemo(() => mapCategoriesToTree(categories), [categories]);

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            }, { field: 'CategoryId', type: "number", width: 80, hideable: false },
            // { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "View", "/products/products?field=ProductType_Key&value=", "CategoryId", "View Sales Items"), type: 'actions', headerName: 'View Sales Items', minWidth: 80, maxWidth: 180, flex: 0.8, },
        ],
        [],
    );

    const tableName = "CategoriesTable";
    let tableSettings = useTableSettings(columns, rows, tableName, true);
    tableSettings.idRow = ID_FIELD_CATEGORIES;
    tableSettings.treeData = true;
    //tableSettings.defaultGroupingExpansionDepth = 0;
    tableSettings.groupingColDef = {
        headerName: "Categories",
        minWidth: 200,
        filterable: true,
        disableColumnMenu: false,
        sortable: true,
        hideDescendantCount: false,
        flex: 2,
        valueGetter: (params) => params.row.Name,
    }
    tableSettings.hasToolbar = true;
    return [(rows ?? []), columns, tableSettings];
};



export const useCategoriesTableOnSalesItem = (initialValues): [
    rows: any[],
    columns: GridColDef[],
    tableSettings: tableSettingsProps,
    changedCategories: any[],
    setChangedCategories: React.Dispatch<React.SetStateAction<any[]>>,
    selectCategoriesOpen: number | GridRowId,
    setSelectCategoriesOpen: React.Dispatch<React.SetStateAction<number | GridRowId>>,
] => {
    const [changedCategories, setChangedCategories] = useState([])
    const [selectCategoriesOpen, setSelectCategoriesOpen] = useState<number | GridRowId>(-1)
    useUpdateEffect(() => {
        if (changedCategories?.length < 1) {
            setChangedCategories(initialValues?.Categories?.filter(category => category?.GroupTreeNode?.Key > 0))
        }
    }, [initialValues?.Categories])

    const rows = React.useMemo<GridColumns<any>>(
        () => [...(changedCategories ?? [])], [changedCategories]);
    let tableSettings: tableSettingsProps = {};
    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: 'NodePath', headerName: 'Categories', flex: 1,
                renderCell: (params: GridRenderCellParams<any>) => { return (<div onClick={() => { setSelectCategoriesOpen(params.id) }} >{params.value}</div>) }
            },
            { field: 'GroupTreeNode_Value', headerName: 'GroupTreeNode', valueGetter: tableHelper.getFieldKeyOrValue, },
            { field: 'GroupTree_Value', headerName: 'GroupTree', valueGetter: tableHelper.getFieldKeyOrValue, width: 180 },
            { field: 'GroupTreeNode_Key', headerName: 'GroupTreeNode Id', valueGetter: tableHelper.getFieldKeyOrValue, },
            {
                field: 'actions', getActions: (params: GridRowParams) => tableHelper.deleteButton(params, setChangedCategories, changedCategories, tableSettings)
                , type: 'actions', headerName: 'Delete', minWidth: 80, maxWidth: 120, flex: 0.8,
            },
        ],
        [changedCategories, tableSettings, setChangedCategories, tableSettings],
    );
    const tableName = "CategoriesTableOnSalesItem";
    tableSettings = useTableSettings(columns, rows, tableName);
    tableSettings.idRow = ID_FIELD_CATEGORIES_ON_SALES_ITEMS;
    tableSettings.autoHeight = true;
    tableSettings.hasToolbar = false;
    return [(rows ?? []), columns, tableSettings, changedCategories, setChangedCategories, selectCategoriesOpen, setSelectCategoriesOpen];
};


export const useCategoriesSelectTable = (): [rows: any[], columns: GridColDef[], tableSettings: tableSettingsProps] => {
    const categories: CategoryResponse[] = useGetCategories();
    const rows = mapCategoriesToTree(categories)
    const columns: GridColumns = React.useMemo<GridColumns<any>>(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,

            },
            { field: 'CategoryId', headerName: "Id", type: "number", width: 80 },
        ], []);
    const tableName = "CategoriesSelectTable";
    const tableSettings = useTableSettings(columns, rows, tableName, true);
    tableSettings.idRow = ID_FIELD_CATEGORIES;
    tableSettings.treeData = true;
    tableSettings.groupingColDef = {
        headerName: "Categories",
        filterable: true,
        disableColumnMenu: false,
        sortable: true,
        hideable: false,
        hideDescendantCount: false,
        minWidth: 200,
        flex: 2,
        valueGetter: (params) => params.row.Name,
    }
    return [(rows ?? []), columns, tableSettings];
};



